import React, { useContext, useEffect } from 'react'
import SubClass from './SubClass'
import NavBar from '../../components/NavBar'
import DataEnq from './DataEnq'
import Footer from '../home_page/Footer'
import CustomAlert from '../globals/CustomAlert'
import { useNavigate } from 'react-router-dom'
import { AuthUserContext } from '../../context/auth_user_state'
import { baseUrl } from '../..'
import axios from 'axios'
import { AuthUser } from '../../models/AuthUser'
import LoadingIcon from '../../components/LoadingIcon'
import { NetworkContext } from '../../context/network_context'
import BackButton from '../../components/BackButton'

const DataSub = () => {

    const { authUser, setAuthUser } = useContext(AuthUserContext);
    const { networks, setNetworks } = useContext(NetworkContext)
    const navigate = useNavigate()
    const getUser = async () => {
        try {
            const response = await axios.get(baseUrl + "/auth/user", {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`
                }
            })
            if (setAuthUser) setAuthUser(response.data.user as AuthUser);
        } catch (err) {
            navigate("/")
        }

    }
    const fetchPriceHandler = async () => {

        try {
            const response = await axios.get(`${baseUrl}/data/fetch-plans`);
            if (setNetworks) setNetworks(response.data["prices"])

        } catch (err) {

        }
    }
    useEffect(() => {
        fetchPriceHandler()
        if (authUser === undefined) {
            getUser()
        }
    }, [authUser])

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: "smooth" })
    })
    return (
        <div >
            {authUser !== undefined ? <div>
                <NavBar />
                <BackButton/>
                <SubClass />
                <DataEnq />
                <Footer />
                <CustomAlert />
            </div> : <LoadingIcon />}

        </div>
    )
}

export default DataSub