import { Autocomplete, TextField } from '@mui/material';
import React, { useContext, useState } from 'react'
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { MdOutlineContactPage } from "react-icons/md"
import MTNlogo from "../../assets/par_mtn.png";
import Airtellogo from "../../assets/par_airtel.png";
import GLOlogo from "../../assets/par_glo.png";
import Mobilelogo from "../../assets/par_9mobile.png";
import Select from 'react-select';
import { AuthUserContext } from '../../context/auth_user_state';
import CustomSelect from '../../components/ImageSelect';
import axios from 'axios';
import { baseUrl } from '../..';
import ConfirmTransaction from '../../alerts/ConfirmTransaction';
import { AlertContext } from '../../context/alert_state';
import Predicter from '../../components/Predicter';



const theme = createTheme({
    palette: {
        primary: {
            light: "#cc300d",
            main: "#cc300d"
        },
        secondary: {
            main: "#cc300d",
            light: "#cc300d"
        }
    },
});


interface NetworkInterface {
    image: string,
    label: string,
    id: string
}
export const allNetworks: NetworkInterface[] = [{ id: "1", image: MTNlogo, label: "MTN" },
{ id: "2", image: GLOlogo, label: "GLO" }, { id: "3", image: Airtellogo, label: "Airtel" }, { id: "6", image: Mobilelogo, label: "9Mobile" }
]

const AirtimeClass = () => {

    const { authUser, setAuthUser } = useContext(AuthUserContext)
    type ValueType = string | null;
    const [value, setValue] = useState<ValueType>(null);
    const [inputValue, setInputValue] = useState('');

    // Define your list of suggestions
    const suggestions = authUser?.recentCreditedNumber ?? [];

    const handleInputChange = (event: any, newInputValue: string) => {
        setInputValue(newInputValue);
    };
    const [networkIndex, setNetworkIndex] = useState(0)

    const [selectedOption, setSelectedOption] = useState<NetworkInterface | null>(null);
    const amountToPurchase = [100, 200, 500, 1000, 2000];
    const [currentAmount, setCurrentAmount] = useState(0)
    const { alert, setAlert } = useContext(AlertContext)
    const [currentIndex, setCurrentIndex] = useState(-1)
    const [incompleteError, setIncompleteError] = useState(false)
    const buyAirtime = async () => {
        return await axios.post(baseUrl + "/purchase/airtime", {
            "networkID": allNetworks[networkIndex].id,
            "phoneNumber": value?.toString().trim() ?? inputValue.trim(),
            "amount": currentAmount
        }, { headers: { Authorization: `Bearer ${localStorage.getItem("token")}` } })
    }

    const handlePurchaseAirtime = async () => {
        if (setAlert) setAlert({
            showAlert: true,
            alertContent: <ConfirmTransaction callback={buyAirtime} price={currentAmount * 0.999} header={"# " + currentAmount.toString()} details={<p>
                Are you sure you want to buy Airtime for <span className='font-bold'>{value?.toString().trim() ?? inputValue.trim()}</span>
            </p>} />,
            alertDismissible: false
        })



    }


    return (
        <ThemeProvider theme={theme}>
            <div className='w-[98vw] pt-[20px] pb-[20px]'>
                <div className='flex flex-col  w-full  lg:max-w-5xl  md:max-w-[540px] md:px-0 px-[15px] mx-auto my-2'>
                    <div className='flex md:flex-row flex-col justify-start  md:justify-between  w-full md:items-end items-start'>
                        <p className='font-Bestigia md:text-4xl text-2xl font-bold text-connect-red'>Airtime Top Up</p>
                        <p>Available Balance: <span className='text-connect-red'># {parseFloat(authUser?.walletBalance.toString() ?? "0").toLocaleString("en-US")}</span></p>
                    </div>
                    <div className='my-5 flex w-[96%] md:items-center items-start'>
                        <CustomSelect onChange={(id, index) => {
                            setNetworkIndex(index)
                        }} />
                        <Autocomplete
                            className='w-full px-4'
                            freeSolo
                            options={suggestions}
                            value={value}
                            inputValue={inputValue}
                            onInputChange={handleInputChange}
                            onChange={(event, newValue) => {
                                setValue(newValue);
                            }}
                            renderInput={(params) => (
                                <TextField {...params} placeholder='Insert Phone Number'
                                    variant="standard" />
                            )}
                        />

                        <MdOutlineContactPage size={30} className='text-connect-red' />
                    </div>


                    <Predicter value={value?.toString() ?? inputValue} />
                    {/* Data Cards */}
                    <div className='grid  lg:grid-cols-5 grid-cols-3 justify-center '>
                        {amountToPurchase.map((e, index) => <div className={`md:w-[160px] w-[90px] h-[60px] md:h-[120px] shadow-md p-3 rounded-md m-3 border border-connect-red flex flex-col items-center justify-evenly cursor-pointer ${index === currentIndex ? "bg-connect-red text-white" : ""}`} onClick={(_) => {
                            setCurrentAmount(amountToPurchase[index])
                            setCurrentIndex(index);
                        }}>
                            <p className='md:text-3xl text-base font-bold' >&#x20A6; {e.toLocaleString("en-US")}</p>

                        </div>)}

                    </div>
                    <div className='my-10'>
                        <p className='font-bold md:text-3xl text-xl inline mx-2 mr-2'>&#x20A6;</p>
                        <input placeholder='Enter custom amount' className='md:text-2xl text-base  border-b focus:border-b focus:outline-none outline-none px-3 w-[50%]' type='number' min={100} onFocus={(e) => {

                            setCurrentIndex(-1)
                        }} onChange={(e) => {
                            setCurrentIndex(-1)
                            setCurrentAmount(parseFloat(e.target.value));
                        }} />

                    </div>
                    {incompleteError && <p className='my-2 text-connect-red text-xs'>Number must be 11 digits </p>}
                    <div className='mt-10 cursor-pointer' onClick={(_) => {
                        if (value?.toString().trim().length === 11 || inputValue.trim().length === 11) {
                            handlePurchaseAirtime()
                        }
                        else {
                            setIncompleteError(true);
                        }
                    }}>

                        <p className='md:max-w-[500px] max-w-[250px] text-center rounded-2xl mx-auto py-3 bg-yellow'>Conitnue</p>
                    </div>

                </div>
                <div>

                </div>


            </div>
        </ThemeProvider>
    )
}

export default AirtimeClass