import React, { ReactNode, useContext, useState } from 'react'
import { GiCancel } from "react-icons/gi"
import { AlertContext } from '../context/alert_state'
import LoadingIcon from '../components/LoadingIcon'
import Sucess from "../assets/success.png";
import Failed from "../assets/failed.png"
import { AxiosError } from 'axios';

interface ConfirmTransactionInterface {
    header: string,
    details: ReactNode,
    price?: number,
    callback: () => Promise<any>
}


const ConfirmTransaction = (props: ConfirmTransactionInterface) => {
    const { alert, setAlert } = useContext(AlertContext)
    const [isLoadind, setIsLoading] = useState<boolean | null>(null)
    const [isError, setIsError] = useState<boolean | null>(null)
    const [errMessage, setErrorMessage] = useState("")

    const handleCall = async () => {
        setIsLoading(true)

        try {
            const response = await props.callback()
            setIsLoading(false)
            setIsError(false)
        } catch (err) {
            // console.log(err)
            const error = err as AxiosError;
            const message = error.response?.data as { error: string | undefined, message: string | undefined }
            setIsLoading(false)
            setIsError(true)
            const finalMessage = message.error !== undefined ? message.error : message.message
            setErrorMessage(finalMessage ?? error.message)
        }
    }


    return (
        <div className='w-[250px] md:w-[350px]  bg-white px-5 md:px-10 py-3 rounded-xl text-center'>
            {!isLoadind ? <div>
                {isError == null ? <div>
                    <p className='md:text-3xl text-lg text-connect-red font-bold my-5'>{props.header}</p>
                    {props.details}
                    <p className='px-3 md:px-10 py-2 md:py-3 bg-yellow rounded-xl cursor-pointer my-3' onClick={async (_) => {
                        handleCall()

                    }}> {props.price !== undefined ? `Pay # ${props.price.toLocaleString("en-US")}` : "Contimue"}</p>
                    <div className='flex justify-center items-center text-connect-red my-3 cursor-pointer' onClick={(_) => {
                        if (setAlert) setAlert({
                            alertContent: <></>,
                            showAlert: false,
                            alertDismissible: true
                        })
                    }}>
                        <p className='mx-2'>Cancel </p><GiCancel />
                    </div>
                </div> : isError === true ? <div className='flex flex-col justify-center items-center'>
                    <div className='h-[100px] w-[100px]'>
                        <img className='object-contain' src={Failed} />

                    </div>
                    <p className='text-center text-connect-red '>{errMessage}</p>
                    {/* <p className='text-center px-5 md:px-10 py-2 bg-yellow  rounded-lg mt-5 mb-2 cursor-pointer' onClick={(_) => {
                        handleCall()
                    }}> Try Again</p> */}
                    <div className='flex justify-center items-center text-connect-red my-1 cursor-pointer' onClick={(_) => {
                        if (setAlert) setAlert({
                            alertContent: <></>,
                            showAlert: false,
                            alertDismissible: true
                        })
                    }}>
                        <p className='text-center px-5 md:px-10 py-2 bg-yellow  rounded-lg mt-5 mb-2 cursor-pointer flex items-center'>Cancel <GiCancel className='mx-2 ' /></p>
                    </div>
                </div> : <div className='flex flex-col justify-center items-center'>
                    <div className='h-[100px] w-[100px]'>
                        <img className='object-contain' src={Sucess} />

                    </div>
                    <p className='text-center text-green '>Transaction Sucessful</p>
                    <p className='text-center px-10 py-2 bg-green text-white  rounded-lg my-5 cursor-pointer' onClick={(_) => {
                        if (setAlert) setAlert({ alertContent: <></>, showAlert: false, alertDismissible: true });
                    }}> Continue</p>

                </div>}
            </div> : <div className='w-full flex flex-col justify-center items-center'>
                <LoadingIcon size={40} color='#FFC233' className='animate-spin mx-auto' />
                <p className='animate-pulse my-5'>Loading. Please wait</p>

            </div>}


        </div>
    )
}

export default ConfirmTransaction