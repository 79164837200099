import React, { useContext, useEffect } from 'react'
import { AuthUserContext } from '../../context/auth_user_state';
import { useNavigate } from 'react-router-dom';
import { baseUrl } from '../..';
import axios from 'axios';
import { AuthUser } from '../../models/AuthUser';
import NavBar from '../../components/NavBar';
import Footer from '../home_page/Footer';
import LoadingScreen from '../../alerts/LoadingScreen';

import CustomAlert from '../globals/CustomAlert';
import CardSub from './CardSub';
import BackButton from '../../components/BackButton';

const ExamCard = () => {
    const { authUser, setAuthUser } = useContext(AuthUserContext);
    const navigate = useNavigate()
    const getUser = async () => {
        try {
            const response = await axios.get(baseUrl + "/auth/user", {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`
                }
            })
            if (setAuthUser) setAuthUser(response.data.user as AuthUser);
        } catch (err) {
            navigate("/")
        }

    }

    useEffect(() => {
        if (authUser === undefined) {
            getUser()
        }
    }, [authUser])

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: "smooth" })
    })

    return (
        <div className=' overflow-clip'>
            {authUser !== undefined ? <div>
                <NavBar />
                <BackButton/>
                <CardSub />
                <Footer />
                <CustomAlert />
            </div> : <LoadingScreen />}

        </div>
    )

}

export default ExamCard