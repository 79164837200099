import React from 'react'
import { Link } from 'react-router-dom';
import NavBar from '../../components/NavBar';

const ErrorScreen = () => {
    return (
        <div className="flex flex-col items-center justify-center h-screen">
            <NavBar />
            <h1 className="text-4xl font-bold mb-4 text-center">Oops! Something went wrong</h1>
            <p className="text-lg mb-4 text-center">We apologize for the inconvenience. Please inform us.</p>
            <Link to="/" className="text-connect-red hover:underline">Go to Home</Link>
        </div>

    )
}

export default ErrorScreen