import React, { useEffect } from 'react'

interface PredicterProps {
    value: string
}

const Predicter = (props: PredicterProps) => {
    const simTypes = [
        {
            simName: "Mtn",
            starts: ["0803", "0816", "0903", "0810", "0806", "0703", "0706", "0813", "0814", "0906"]
        },
        { simName: "Glo", starts: ["0805", "0905", "0807", "0811", "0705", "0815"] },
        { simName: "Airtel", starts: ["0907", "0708", "0802", "0902", "0812", "0808", "0701"] },
        { simName: "9Mobile", starts: ["0909", "0908", "0818", "0809", "0817"] }
    ];
    const getSimType = (phoneNumber: string): string | undefined => {
        for (const simType in simTypes) {
            // console.log(simType)
            for (const prefix of simTypes[simType].starts) {
                if (phoneNumber.startsWith(prefix)) {
                    return `Detected Sim Type: ${simTypes[simType].simName}.`;
                }
            }
        }
    }
    useEffect(() => {

    }, [props.value])

    return (
        <div>
            <div className='text-sm text-connect-red font-bold'>{getSimType(props.value)}</div>
            {getSimType(props.value) && <p className='text-sm'>Please ignore this warning if you are sure</p>}
        </div>


    )

}

export default Predicter