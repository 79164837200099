import { CustomAccordion } from './CustomAccordion';
import React, { ReactElement, useState } from 'react'
import { IconType } from "react-icons"
import { AiOutlineQuestionCircle } from "react-icons/ai"
import { BsFillForwardFill } from "react-icons/bs"
import { MdOutlineExpandMore } from 'react-icons/md'
import { motion, AnimatePresence } from "framer-motion";
import { Accordion, AccordionSummary, AccordionDetails, Typography } from '@mui/material'

interface QuestionInterface {
    mainSection: string,
    subSections: SubSectionInterface[],
    icon: ReactElement
}
export interface SubSectionInterface {
    subSectionQuestion: string,
    subSectionAnswer: string
}

type FaqInterface = QuestionInterface[];



const faqs: FaqInterface = [
    {
        mainSection: "General Questions",
        subSections: [
            { subSectionQuestion: "What Services does ConnectSphere provide?", subSectionAnswer: "ConnectSphere provides affordable data bundles, ensuring reliable and budget-friendly mobile connectivity. Our E-pins service facilitates easy online result checking for exams like WAEC, NECO, and JAMB. Elevate your entertainment with our competitively priced cable TV subscriptions. We also simplify life with convenient electricity bill payments. At ConnectSphere, we're committed to making connectivity faster, easier, and affordable. Stay tuned for new services enhancing your digital experience. Explore our offerings—your trusted partner for seamless connectivity in a compact digital world." },
            { subSectionQuestion: "What is the pricing structure at ConnectSphere?", subSectionAnswer: "At ConnectSphere, we offer transparent and competitive pricing for our services. Our pricing structure is designed to provide you with options that suit your budget and specific connectivity needs. You can find detailed information about our pricing plans on our landing page. We believe in offering affordable and flexible solutions that cater to both personal and business users. Choose the plan that best aligns with your requirements and enjoy seamless connectivity without breaking the bank." },
            { subSectionQuestion: "Can I use ConnectSphere on multiple devices?", subSectionAnswer: "Absolutely! ConnectSphere is designed to provide a seamless user experience across multiple devices. Whether you're using a smartphone, laptop, or tablet, our platform is responsive and adaptable, ensuring optimal performance and compatibility. You can easily access ConnectSphere and enjoy its features on any of your preferred devices. With our user-friendly interface, you can seamlessly switch between devices without compromising on functionality or convenience. Stay connected on the go, at the office, or in the comfort of your home, knowing that ConnectSphere is accessible across all your devices." },
            { subSectionQuestion: "Can I share data with another person?", subSectionAnswer: "With ConnectSphere, sharing data with others becomes convenient and hassle-free, enabling you to extend connectivity to those who need it. ConnectSphere allows you to share your data with other devices or users. Simply enter the details of the intended user or beneficiary, and we will facilitate the loading of their account using your wallet balance. However, it's crucial to ensure the security of your login credentials and PIN to prevent unauthorized access or usage of your account. Keeping your login information safe will help maintain control over the data allocation and usage. Experience the flexibility and convenience of data sharing with ConnectSphere's user-friendly features." },
            // { subSectionQuestion: "Why should I use ConnectSphere", subSectionAnswer: "Lorem ipsum dolor sit amet consectetur. Sit netus auctor sagittis venenatis venenatis. Aliquam commodo ac amet enim pulvinar. Cursus erat sollicitudin aliquam felis dolor nisi commodo diam nibh. Dis diam pharetra ornare quam. Nisi lorem quis in sem tellus hac." },
            // { subSectionQuestion: "Why should I use ConnectSphere", subSectionAnswer: "Lorem ipsum dolor sit amet consectetur. Sit netus auctor sagittis venenatis venenatis. Aliquam commodo ac amet enim pulvinar. Cursus erat sollicitudin aliquam felis dolor nisi commodo diam nibh. Dis diam pharetra ornare quam. Nisi lorem quis in sem tellus hac." }
        ],
        icon: <AiOutlineQuestionCircle size={25} />
    },
    {
        mainSection: "Data Bundle Questions",
        subSections: [
            { subSectionQuestion: "What Services does ConnectSphere provide?", subSectionAnswer: "ConnectSphere provides affordable data bundles, ensuring reliable and budget-friendly mobile connectivity. Our E-pins service facilitates easy online result checking for exams like WAEC, NECO, and JAMB. Elevate your entertainment with our competitively priced cable TV subscriptions. We also simplify life with convenient electricity bill payments. At ConnectSphere, we're committed to making connectivity faster, easier, and affordable. Stay tuned for new services enhancing your digital experience. Explore our offerings—your trusted partner for seamless connectivity in a compact digital world." },
            { subSectionQuestion: "What is the pricing structure at ConnectSphere?", subSectionAnswer: "At ConnectSphere, we offer transparent and competitive pricing for our services. Our pricing structure is designed to provide you with options that suit your budget and specific connectivity needs. You can find detailed information about our pricing plans on our landing page. We believe in offering affordable and flexible solutions that cater to both personal and business users. Choose the plan that best aligns with your requirements and enjoy seamless connectivity without breaking the bank." },
            { subSectionQuestion: "Can I use ConnectSphere on multiple devices?", subSectionAnswer: "Absolutely! ConnectSphere is designed to provide a seamless user experience across multiple devices. Whether you're using a smartphone, laptop, or tablet, our platform is responsive and adaptable, ensuring optimal performance and compatibility. You can easily access ConnectSphere and enjoy its features on any of your preferred devices. With our user-friendly interface, you can seamlessly switch between devices without compromising on functionality or convenience. Stay connected on the go, at the office, or in the comfort of your home, knowing that ConnectSphere is accessible across all your devices." },
            { subSectionQuestion: "Can I share data with another person?", subSectionAnswer: "With ConnectSphere, sharing data with others becomes convenient and hassle-free, enabling you to extend connectivity to those who need it. ConnectSphere allows you to share your data with other devices or users. Simply enter the details of the intended user or beneficiary, and we will facilitate the loading of their account using your wallet balance. However, it's crucial to ensure the security of your login credentials and PIN to prevent unauthorized access or usage of your account. Keeping your login information safe will help maintain control over the data allocation and usage. Experience the flexibility and convenience of data sharing with ConnectSphere's user-friendly features." },
        ],
        icon: <AiOutlineQuestionCircle size={25} />
    }, {
        mainSection: "Airtime Topup Questions",
        subSections: [
            { subSectionQuestion: "What Services does ConnectSphere provide?", subSectionAnswer: "ConnectSphere provides affordable data bundles, ensuring reliable and budget-friendly mobile connectivity. Our E-pins service facilitates easy online result checking for exams like WAEC, NECO, and JAMB. Elevate your entertainment with our competitively priced cable TV subscriptions. We also simplify life with convenient electricity bill payments. At ConnectSphere, we're committed to making connectivity faster, easier, and affordable. Stay tuned for new services enhancing your digital experience. Explore our offerings—your trusted partner for seamless connectivity in a compact digital world." },
            { subSectionQuestion: "What is the pricing structure at ConnectSphere?", subSectionAnswer: "At ConnectSphere, we offer transparent and competitive pricing for our services. Our pricing structure is designed to provide you with options that suit your budget and specific connectivity needs. You can find detailed information about our pricing plans on our landing page. We believe in offering affordable and flexible solutions that cater to both personal and business users. Choose the plan that best aligns with your requirements and enjoy seamless connectivity without breaking the bank." },
            { subSectionQuestion: "Can I use ConnectSphere on multiple devices?", subSectionAnswer: "Absolutely! ConnectSphere is designed to provide a seamless user experience across multiple devices. Whether you're using a smartphone, laptop, or tablet, our platform is responsive and adaptable, ensuring optimal performance and compatibility. You can easily access ConnectSphere and enjoy its features on any of your preferred devices. With our user-friendly interface, you can seamlessly switch between devices without compromising on functionality or convenience. Stay connected on the go, at the office, or in the comfort of your home, knowing that ConnectSphere is accessible across all your devices." },
            { subSectionQuestion: "Can I share data with another person?", subSectionAnswer: "With ConnectSphere, sharing data with others becomes convenient and hassle-free, enabling you to extend connectivity to those who need it. ConnectSphere allows you to share your data with other devices or users. Simply enter the details of the intended user or beneficiary, and we will facilitate the loading of their account using your wallet balance. However, it's crucial to ensure the security of your login credentials and PIN to prevent unauthorized access or usage of your account. Keeping your login information safe will help maintain control over the data allocation and usage. Experience the flexibility and convenience of data sharing with ConnectSphere's user-friendly features." },
        ],
        icon: <AiOutlineQuestionCircle size={25} />
    }, {
        mainSection: "Utility Bill Questions",
        subSections: [
            { subSectionQuestion: "What Services does ConnectSphere provide?", subSectionAnswer: "ConnectSphere provides affordable data bundles, ensuring reliable and budget-friendly mobile connectivity. Our E-pins service facilitates easy online result checking for exams like WAEC, NECO, and JAMB. Elevate your entertainment with our competitively priced cable TV subscriptions. We also simplify life with convenient electricity bill payments. At ConnectSphere, we're committed to making connectivity faster, easier, and affordable. Stay tuned for new services enhancing your digital experience. Explore our offerings—your trusted partner for seamless connectivity in a compact digital world." },
            { subSectionQuestion: "What is the pricing structure at ConnectSphere?", subSectionAnswer: "At ConnectSphere, we offer transparent and competitive pricing for our services. Our pricing structure is designed to provide you with options that suit your budget and specific connectivity needs. You can find detailed information about our pricing plans on our landing page. We believe in offering affordable and flexible solutions that cater to both personal and business users. Choose the plan that best aligns with your requirements and enjoy seamless connectivity without breaking the bank." },
            { subSectionQuestion: "Can I use ConnectSphere on multiple devices?", subSectionAnswer: "Absolutely! ConnectSphere is designed to provide a seamless user experience across multiple devices. Whether you're using a smartphone, laptop, or tablet, our platform is responsive and adaptable, ensuring optimal performance and compatibility. You can easily access ConnectSphere and enjoy its features on any of your preferred devices. With our user-friendly interface, you can seamlessly switch between devices without compromising on functionality or convenience. Stay connected on the go, at the office, or in the comfort of your home, knowing that ConnectSphere is accessible across all your devices." },
            { subSectionQuestion: "Can I share data with another person?", subSectionAnswer: "With ConnectSphere, sharing data with others becomes convenient and hassle-free, enabling you to extend connectivity to those who need it. ConnectSphere allows you to share your data with other devices or users. Simply enter the details of the intended user or beneficiary, and we will facilitate the loading of their account using your wallet balance. However, it's crucial to ensure the security of your login credentials and PIN to prevent unauthorized access or usage of your account. Keeping your login information safe will help maintain control over the data allocation and usage. Experience the flexibility and convenience of data sharing with ConnectSphere's user-friendly features." },
        ],
        icon: <AiOutlineQuestionCircle size={25} />
    },
    {
        mainSection: "Cable TV Questions",
        subSections: [
            { subSectionQuestion: "What Services does ConnectSphere provide?", subSectionAnswer: "ConnectSphere provides affordable data bundles, ensuring reliable and budget-friendly mobile connectivity. Our E-pins service facilitates easy online result checking for exams like WAEC, NECO, and JAMB. Elevate your entertainment with our competitively priced cable TV subscriptions. We also simplify life with convenient electricity bill payments. At ConnectSphere, we're committed to making connectivity faster, easier, and affordable. Stay tuned for new services enhancing your digital experience. Explore our offerings—your trusted partner for seamless connectivity in a compact digital world." },
            { subSectionQuestion: "What is the pricing structure at ConnectSphere?", subSectionAnswer: "At ConnectSphere, we offer transparent and competitive pricing for our services. Our pricing structure is designed to provide you with options that suit your budget and specific connectivity needs. You can find detailed information about our pricing plans on our landing page. We believe in offering affordable and flexible solutions that cater to both personal and business users. Choose the plan that best aligns with your requirements and enjoy seamless connectivity without breaking the bank." },
            { subSectionQuestion: "Can I use ConnectSphere on multiple devices?", subSectionAnswer: "Absolutely! ConnectSphere is designed to provide a seamless user experience across multiple devices. Whether you're using a smartphone, laptop, or tablet, our platform is responsive and adaptable, ensuring optimal performance and compatibility. You can easily access ConnectSphere and enjoy its features on any of your preferred devices. With our user-friendly interface, you can seamlessly switch between devices without compromising on functionality or convenience. Stay connected on the go, at the office, or in the comfort of your home, knowing that ConnectSphere is accessible across all your devices." },
            { subSectionQuestion: "Can I share data with another person?", subSectionAnswer: "With ConnectSphere, sharing data with others becomes convenient and hassle-free, enabling you to extend connectivity to those who need it. ConnectSphere allows you to share your data with other devices or users. Simply enter the details of the intended user or beneficiary, and we will facilitate the loading of their account using your wallet balance. However, it's crucial to ensure the security of your login credentials and PIN to prevent unauthorized access or usage of your account. Keeping your login information safe will help maintain control over the data allocation and usage. Experience the flexibility and convenience of data sharing with ConnectSphere's user-friendly features." },
        ],
        icon: <AiOutlineQuestionCircle size={25} />
    }
]


const FAQs = () => {

    const [currSection, setCurrSection] = useState(0)
    return (
        <div id='faqs' className='w-full  relative py-[10px] md:py-[40px] overflow-clip'>
            {/* This is the content container */}
            <div className='flex flex-col items-center  w-full  lg:max-w-5xl  md:max-w-[540px] md:px-0 px-[15px] mx-auto my-2'>
                <p className='font-Bestigia md:text-4xl text-2xl font-bold text-connect-red'>FAQs</p>
                <div className='flex my-4 md:my-8 w-full md:flex-row flex-col'>
                    {/* Card 1 */}
                    {/* <div className='bg-connect-red mx-10 rounded-lg p-8 hidden md:block md:basis-1/2'>

                        {faqs.map((e, index) => (<div onClick={(e) => {
                            setCurrSection(index)
                        }} className={`cursor-pointer flex relative items-center my-4 p-3 bg-connect-red border-white border rounded-full overflow-clip ${currSection === index ? "text-black" : "text-white"}`}>
                            <AnimatePresence>


                                {index === currSection && <motion.span

                                    key={index}
                                    initial={{
                                        width: 0, opacity: 0
                                    }}
                                    animate={{
                                        width: 1000, opacity: 1
                                    }}
                                    exit={{ width: 0, opacity: 0 }}

                                    className='absolute bg-white top-[-10px] left-[-10px]  w-[1000px] h-[1000px]'></motion.span>}
                            </AnimatePresence>
                            <div className='z-10 flex  items-center'>
                                {e.icon}
                                <p className='px-3'>{e.mainSection}</p>
                            </div>

                        </div>))}
                        <div onClick={(e) => {

                        }} className={`cursor-pointer flex flex-row justify-evenly items-center my-4 p-4  bg-connect-red border-white border rounded-full overflow-clip text-white `}>

                            <div className='z-10 flex flex-col items-start'>
                                <p>Still have questions?</p>
                                <p className='px-3'>Contact us</p>
                            </div>
                            <BsFillForwardFill size={25} />
                        </div>
                    </div> */}
                    {/* Card 2 */}
                    <div className='md:basis-1/2 mx-10 mt-10 md:mt-0'>
                        {faqs[currSection].subSections.map((e, index) => <CustomAccordion index={index} currSection={currSection} subSection={e} />
                        )}

                    </div>

                </div>


            </div>

            <div className='absolute top-[200px] -z-10 right-[-50px] bg-[url("/src/assets/connect-bg.png")]  bg-contain   md:w-[400px] md:h-[400px] w-[300px] h-[300px]'>

            </div>

        </div>
    )
}

export default FAQs