import { PartnerList } from './PartnerList';
import React, { useContext, useEffect, useState } from 'react'
import axios from 'axios'
import { baseUrl } from '../..'
import { motion, AnimatePresence } from 'framer-motion'
import { MdExpandMore } from "react-icons/md"
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { NetworkContext } from '../../context/network_context';





interface DataPricingInterface {
    planID: string,
    displayName: string,
    validity: string,
    price: number,
    enabled: boolean,
    _id: string,

}


interface NetworkPricingInterface {
    _id: string,
    networkID: string,
    displayName: string,
    enabled: boolean,
    dataPlans: DataPricingInterface[]

}

type PricingType = NetworkPricingInterface[]
const Pricing = () => {
    const { networks, setNetworks } = useContext(NetworkContext)
    const [pricingList, setPricingList] = useState<PricingType>([]);
    const [currentShowing, setCurrentShowing] = useState(0);

    useEffect(() => {
        fetchPriceHandler();
    }, [])
    const fetchPriceHandler = async () => {

        try {
            const response = await axios.get(`${baseUrl}/data/fetch-plans`);
            if (setNetworks) setNetworks(response.data["prices"])

        } catch (err) {

        }
    }
    return (
        <div id='pricing' className='w-full  relative mt-[50px] py-[50px] bg-service-gold '>
            <div className=' lg:max-w-5xl  md:max-w-[540px] md:px-0 px-[15px] mx-auto  opacity-0'>
                <div className=' shadow-lg '>
                    {/* Header */}
                    <div className='bg-yellow py-8 shadow-md'>
                        {networks?.length ?? [].length > 0 ? <div className='flex justify-evenly items-center'>
                            {networks!.map((e, index) => <div key={e._id} className={currentShowing === index ? `text-black flex flex-row items-center cursor-pointer` : `text-grey flex flex-row items-center cursor-pointer`} onClick={(_) => {
                                setCurrentShowing(index)
                            }}>
                                <div className='flex flex-col'>
                                    {e.displayName}
                                    <AnimatePresence>
                                        {currentShowing === index && <motion.span initial={{
                                            opacity: 0
                                        }} animate={{
                                            opacity: 1
                                        }} className='w-full h-[2px] bg-black'></motion.span>}
                                    </AnimatePresence>

                                </div>
                                <MdExpandMore />
                            </div>)}
                        </div> : <div className='flex justify-evenly items-center'>
                            {Array(4).fill("").map((e, index) => <div key={e._id} className={currentShowing === index ? `text-black flex flex-row items-center cursor-pointer` : `text-grey flex flex-row items-center cursor-pointer`} >
                                <div className='flex flex-col w-[50px] h-[10px] bg-connect-red animate-pulse rounded-md'>
                                </div>
                            </div>)}
                        </div>}
                    </div>
                    <div className=' bg-white'>
                        {(networks?.length ?? 0) > 0 && <TableContainer component={Paper} >
                            <Table sx={{ minWidth: 300 }} aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="left" >
                                            <p className='font-bold'>
                                                Data Plans
                                            </p>
                                        </TableCell>
                                        <TableCell align="left" className='font-bold'>
                                            <p className='font-bold'>
                                                Price {'\u20A6'}
                                            </p>
                                        </TableCell>
                                        <TableCell align="left" className='font-bold'>
                                            <p className='font-bold'>
                                                Validity
                                            </p>
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {networks![currentShowing].dataPlans.map((row) => (
                                        <TableRow
                                            key={row._id}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell component="th" scope="row" >
                                                {row.displayName}
                                            </TableCell>
                                            <TableCell align="left">{row.price}</TableCell>
                                            <TableCell align="left">{row.validity}</TableCell>

                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>}

                    </div>
                </div>
                <PartnerList />
            </div>

            <div className='absolute top-20 z-20 w-[99vw] '>

                <div className=' lg:max-w-5xl  md:max-w-[540px] md:px-0 px-[15px] mx-auto '>

                    <div className=' shadow-lg '>
                        {/* Header */}
                        <div className='bg-yellow py-8 shadow-md'>
                            {(networks?.length ?? 0) > 0 ? <div className='flex justify-evenly items-center'>
                                {networks!.map((e, index) => <div key={e._id} className={currentShowing === index ? `text-black flex flex-row items-center cursor-pointer` : `text-grey flex flex-row items-center cursor-pointer`} onClick={(_) => {
                                    setCurrentShowing(index)
                                }}>
                                    <div className='flex flex-col'>
                                        {e.displayName}
                                        <AnimatePresence>
                                            {currentShowing === index && <motion.span initial={{
                                                opacity: 0
                                            }} animate={{
                                                opacity: 1
                                            }} className='w-full h-[2px] bg-black'></motion.span>}
                                        </AnimatePresence>

                                    </div>
                                    <MdExpandMore />
                                </div>)}
                            </div> : <div className='flex justify-evenly items-center'>
                                {Array(4).fill("").map((e, index) => <div key={e._id} className={currentShowing === index ? `text-black flex flex-row items-center cursor-pointer` : `text-grey flex flex-row items-center cursor-pointer`} >
                                    <div className='flex flex-col w-[50px] h-[10px] bg-connect-red animate-pulse rounded-md'>
                                    </div>
                                </div>)}
                            </div>}
                        </div>
                        <div className=' bg-white'>
                            {(networks?.length ?? 0) > 0 && <TableContainer component={Paper} >
                                <Table sx={{ minWidth: 300 }} aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="left" >
                                                <p className='font-bold'>
                                                    Data Plans
                                                </p>
                                            </TableCell>
                                            <TableCell align="left" className='font-bold'>
                                                <p className='font-bold'>
                                                    Price {'\u20A6'}
                                                </p>
                                            </TableCell>
                                            <TableCell align="left" className='font-bold'>
                                                <p className='font-bold'>
                                                    Validity
                                                </p>
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {networks![currentShowing].dataPlans.map((row) => (
                                            <TableRow
                                                key={row._id}
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                            >
                                                <TableCell component="th" scope="row" >
                                                    {row.displayName}
                                                </TableCell>
                                                <TableCell align="left">{row.price}</TableCell>
                                                <TableCell align="left">{row.validity}</TableCell>

                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>}

                        </div>
                    </div>
                    <PartnerList />
                </div>

            </div>
            <div className='absolute top-0 z-10 '>
                <div className='w-[99vw] bg-connect-red h-[150px] pt-8'>
                    <p className='font-Bestigia md:text-4xl text-2xl font-bold text-white text-center mb-10'>Pricing</p>
                </div>
                <div className='bg-service-gold w-[99vw] '>

                </div>
            </div>
        </div>

    )
}

export default Pricing