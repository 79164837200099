import React from 'react'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { PartnerList } from '../home_page/PartnerList';


interface ServiceCodeInterface {
    serviceName: string,
    serviceCode: string
}

const rows: ServiceCodeInterface[] = [
    { serviceName: "MTN SME Data", serviceCode: "*461*4#" },
    { serviceName: "MTN CG Data", serviceCode: "*461*260#" },
    { serviceName: "MTN Gifting Data", serviceCode: "*323*4#" },
    { serviceName: "GLO Data", serviceCode: "*127*0#" },
    { serviceName: "9Mobile SME Data", serviceCode: "*917*9#" },
    { serviceName: "9Mobile Data", serviceCode: "*228#" },
    { serviceName: "Airtel Data", serviceCode: "*140#" },
];
const DataEnq = () => {





    return (
        <div id='faqs' className='w-full  relative pt-[50px] overflow-clip'>
            {/* This is the content container */}
            <div className='flex flex-col items-start  w-full  lg:max-w-5xl  md:max-w-[540px] sm:max-w-[540px] max-w-[340px] mx-auto my-2'>
                <p className='font-Bestigia md:text-4xl text-2xl font-bold text-left text-connect-red'>USSD Enquiry Codes For All Network </p>
                <p className='text-left text-black opacity-60'>How to Check Account Balance</p>
                <div className='flex md:flex-row flex-col  py-10 md:justify-evenly w-full justify-center items-center'>
                    {/* Service Code Table */}
                    <div>
                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: window.innerWidth < 600 ? 300 : 520 }} aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell >
                                            <p className='font-bold text-lg'>Service</p>
                                        </TableCell>
                                        <TableCell align="right" >
                                            <p className='font-bold text-lg'>Code</p>
                                        </TableCell>

                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {rows.map((row, index) => (
                                        <TableRow
                                            key={index}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell component="th" scope="row">
                                                {row.serviceName}
                                            </TableCell>
                                            <TableCell align="right">{row.serviceCode}</TableCell>

                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <PartnerList />
                    </div>
                </div>


            </div>

            <div className='absolute top-[180px] left-0 bg-[url("/src/assets/connect-bg.png")]  bg-contain   md:w-[400px] md:h-[400px] w-[300px] h-[300px]'>

            </div>

        </div>
    )
}

export default DataEnq