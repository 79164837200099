import React from 'react'
import logo from "../assets/logo.png"


const LoadingScreen = () => {
    return (
        <div className="flex items-center justify-center h-screen">
            <img src={logo} alt="Logo" className="w-24 animate-pulse animate-zoom" />
        </div>

    )
}

export default LoadingScreen