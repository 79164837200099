import { Dispatch, SetStateAction, createContext } from "react";
import { AuthUser } from "../models/AuthUser";

interface AuthStateInterface {
    authUser?: AuthUser;
    setAuthUser?: Dispatch<SetStateAction<AuthUser | undefined>>

}



export const AuthUserContext = createContext<AuthStateInterface>({});