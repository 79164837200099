import { useState, useEffect } from "react";
import Car1 from "../../assets/car_1.png";
import Car2 from "../../assets/car_2.png";
import Car3 from "../../assets/car_3.png";

import Car5 from "../../assets/car_5.png";
import Car6 from "../../assets/car_6.png";

import { } from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider, { Settings } from "react-slick";







const ImageCarousel = () => {

    const imageList = [Car1, Car6, Car5];


    var settings: Settings = {
        dots: true,
        infinite: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 4000,
        centerMode: true,

        responsive: [{
            breakpoint: 600,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 2,
                initialSlide: 2
            }
        }]



    };

    return (


        <Slider {...settings} >
            {imageList.map((e, index) => {

                return <div

                    className={` w-[400px] `}>

                    <img className="w-full h-full object-contain " src={e} alt="carousel" />
                </div>;
            })}
        </Slider>










    )
}

export default ImageCarousel