import React, { useContext, useEffect } from 'react'
import NavBar from '../../components/NavBar'
import AccountDetails from './AccountDetails'
import FAQs from '../home_page/FAQs'
import Testimonial from '../home_page/Testimonials'
import Footer from '../home_page/Footer'
import ContactUs from '../home_page/ContactUs'
import OurServices from '../home_page/OurServices'
import { AuthUserContext } from '../../context/auth_user_state'
import LoadingScreen from '../../alerts/LoadingScreen'
import axios from 'axios'
import { baseUrl } from '../..'
import { AuthUser } from '../../models/AuthUser'
import { useNavigate } from 'react-router-dom'
import CustomAlert from '../globals/CustomAlert'
import UserAlert, { UserAlertInterface } from '../../alerts/UserAlert'
import { AlertContext } from '../../context/alert_state'




const DashBoard = () => {
    const { authUser, setAuthUser } = useContext(AuthUserContext);
    const navigate = useNavigate()
    const { alert, setAlert } = useContext(AlertContext)
    const getUser = async () => {
        try {
            const response = await axios.get(baseUrl + "/auth/user", {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`
                }
            })
            if (setAuthUser) {

                setAuthUser(response.data.user as AuthUser)
            };
        } catch (err) {
            console.log(err)
            navigate("/")
        }
    }


    async function fetchAlert() {
        const data = (await axios.get(baseUrl + "/alert/all")).data as UserAlertInterface
        if (data.showAlert) {
            if (setAlert) setAlert({ alertContent: <UserAlert {...data} />, showAlert: true, alertDismissible: !data.showCta })
        }
    }

    useEffect(() => {
        if (authUser === undefined) {
            fetchAlert()
            getUser()
        }
    }, [authUser])

    setTimeout(() => {
        localStorage.setItem("firstTime", "true")
    }, 3000)

    return (
        <div className='w-[98vw] overflow-clip'>
            {authUser !== undefined ? <div>
                <NavBar />
                <AccountDetails />
                <OurServices />
                <FAQs />
                <ContactUs />
                <Footer />
                <CustomAlert />
            </div> : <LoadingScreen />}

        </div>
    )
}

export default DashBoard