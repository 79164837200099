import React, { useContext, useEffect, useState } from 'react'
import Card from "../../assets/aaa.avif"
import { AiFillCopy } from "react-icons/ai"
import { IoIosNotifications, IoIosArrowForward } from "react-icons/io";
import { BsPersonCircle } from "react-icons/bs";
import { AuthUserContext } from '../../context/auth_user_state';
import { useNavigate } from 'react-router-dom';
import { IoRefreshCircleOutline } from "react-icons/io5"
import Joyride from 'react-joyride';
import axios from 'axios';
import { baseUrl } from '../..';

const AccountDetails = () => {
    const [isCopied, setIsCopied] = useState(false);
    const { authUser, setAuthUser } = useContext(AuthUserContext)
    const [updatingBank, setUpdatingBank] = useState(false)
    var steps = [
        {
            target: '#account',
            content: 'Make transfer to the account number below to fund your wallet at zero cost for transactions below #10,000. Stamp duty of #50 applies to transactions above #10,000.',
        },
        {
            target: '#balance',
            content: 'You can view your current wallet balance here',
        },
        {
            target: '#history',
            content: 'View your transaction history here',
        },


    ]
    async function fetchAccountStatus() {
        setUpdatingBank(true)
        try {

            const response = await axios.get(baseUrl + "/cadri/refresh", {
                headers: {
                    Authorization: `Bearer ${authUser?.authToken}`
                }
            })
            if (setAuthUser) setAuthUser(response.data.user)
            setUpdatingBank(false)
        } catch (err) {
            setUpdatingBank(false)
        }
    }
    useEffect(() => {
        fetchAccountStatus()
    }, [])

    const navigate = useNavigate()
    return (
        <div id='home' className='bg-service-gold w-[100vw] pt-[120px] pb-[60px]'>
            {!localStorage.getItem("firstTime") && <Joyride steps={steps} />}
            <div className='flex  w-full  lg:max-w-5xl  md:max-w-[540px] md:px-0 px-[15px] mx-auto my-2'>
                <div className='flex md:flex-row flex-col items-center justify-between w-full'>
                    <div className='flex flex-col my-3'>
                        <div className='md:hidden block'>
                            <IoIosNotifications className='inline mx-2 cursor-pointer' size={23} />
                            <BsPersonCircle className='inline mx-2 text-connect-red cursor-pointer' size={23} />
                            <span className='inline text-xs md:text-base'>{authUser?.email}</span>

                        </div>
                        <p className='my-4 w-[70%]'>Welcome to your dashboard, <span className='font-bold text-lg'>{authUser?.accountName?.replace("ConnectSphere - ", "")}</span> </p>
                        <div className=''>
                            {/* <img src={Card} alt='card' className='w-full h-full object-cover rounded-2xl' /> */}
                            {/* <div className=' bg-gradient-to-br from-service-gold  to-green w-full h-full object-cover rounded-2xl '></div> */}
                            <p className='opacity-70 text-xs md:text-base'>AUTOMATED BANK TRANSFER</p>
                            <p className='  text-[10px] w-[90%] md:w-[65%]'>Make transfer to the account number below to Fund your wallet. Charge Fee is 1%
                                <ul>
                                    <li>Charge for &#8358; 100 is &#8358; 1</li>
                                </ul></p>
                            {((authUser?.accounts.length ?? -1) > 0) ?
                                <div className='flex md:flex-row flex-col  md:gap-x-3 gap-y-4 mt-2'>
                                    {authUser?.accounts.map((e, index) => {
                                        return <div className=' bg-yellow p-3 rounded-lg w-[200px]'>

                                            <p id='account' className='md:text-xl text-md font-bold cursor-pointer'>{e.accountNumber} <span className='mx-1'>
                                                <AiFillCopy className={`inline ${!isCopied ? "text-connect-red" : "text-yellow"}`} onClick={(f) => {
                                                    setIsCopied(true)
                                                    navigator.clipboard.writeText(`${e.accountNumber}` ?? "");
                                                    setTimeout(() => {
                                                        setIsCopied(false);
                                                    }, 3000)
                                                }} /> {isCopied && <span className='text-yellow text-sm'> Copied !</span>}
                                            </span> </p>
                                            <p className='md:text-sm text-[10px]'>Account Name: <span className='font-bold'>ConnectSphere/{e.accountName}</span></p>
                                            <p className='md:text-sm text-[10px]'>Bank Name: <span className='font-bold'>{e.bankName}  </span></p>
                                        </div>
                                    })}
                                </div>



                                : <div className=' font-bold bg-yellow p-3'>
                                    Account Creation Failed. <br />Please Contact Admin
                                </div>}
                        </div>
                    </div>
                    <div className='flex flex-col justify-evenly items-start md:items-end h-full mx-4'>
                        <div className='hidden md:block'>
                            <IoIosNotifications className='inline mx-2 cursor-pointer' size={23} />
                            <BsPersonCircle className='inline mx-2 text-connect-red cursor-pointer' size={23} />
                            <span className='inline text-xs md:text-base'>{authUser?.email}</span>

                        </div>
                        <div className='flex flex-row justify-end items-end'>
                            <div>
                                <p id='balance' className='text-sm md:text-end text-start'>Wallet Balance:</p>
                                <p className='bg-connect-red text-white p-2 text-2xl rounded-lg font-bold '># {parseFloat(authUser?.walletBalance.toString() ?? "0").toLocaleString("en-US")}</p>
                            </div>
                            {/* <div id='refresh' className='w-[55px] h-[35px] p-3 bg-yellow rounded-md mx-10 my-1 cursor-pointer flex justify-center items-center text-connect-red font-bold'>
                                <IoRefreshCircleOutline size={25} className={updatingBank ? "animate-spin" : ""} onClick={(_) => {
                                    fetchAccountStatus()
                                }} />
                            </div> */}

                        </div>

                        <div className='flex flex-col md:items-end items-start max-w-[350px]'>
                            <p className='md:text-end text-start mt-1'>Make transfer to the account number on the card to fund your wallet</p>
                            <p id='history' className='text-connect-red underline cursor-pointer my-2' onClick={(e) => {
                                navigate("/transactions")
                            }} >Transaction History <IoIosArrowForward className='inline' /></p>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default AccountDetails