import React, { useContext, useEffect, useRef, useState } from 'react'
import { AuthUserContext } from '../../context/auth_user_state'
import axios from 'axios';
import { baseUrl } from '../..';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { Autocomplete, TextField } from '@mui/material';
import { AlertContext } from '../../context/alert_state';
import ConfirmTransaction from '../../alerts/ConfirmTransaction';

interface CardInterface {
    _id: string,
    examID: string,
    displayName: string,
    amount: number,
    enabled: boolean,
}

const theme = createTheme({
    palette: {
        primary: {
            light: "#cc300d",
            main: "#cc300d"
        },
        secondary: {
            main: "#cc300d",
            light: "#cc300d"
        }
    },
});
type ValueType = string | null;
const CardSub = () => {

    const [cards, setCards] = useState<CardInterface[]>([]);
    const [currentIndex, setCurrentIndex] = useState<number>(0)
    const [value, setValue] = useState<ValueType>(null);
    const [inputValue, setInputValue] = useState('');
    const { authUser } = useContext(AuthUserContext)
    const quantityRef = useRef<HTMLInputElement | null>(null)
    const { alert, setAlert } = useContext(AlertContext)
    const fetchCards = async () => {
        const response = (await axios.get(baseUrl + "/epin/prices")).data as { pin: CardInterface[] }
        setCards(response.pin)
    }

    useEffect(() => {
        fetchCards()
    }, [cards])
    const handleInputChange = (event: any, newInputValue: string) => {
        setInputValue(newInputValue);
    };

    const buyCard = async () => {
        return await axios.post(baseUrl + "/epin/purchase", {
            "serviceID": cards[currentIndex].examID,
            "quantity": parseFloat(quantityRef.current?.value ?? "1")
        }, {
            headers: { Authorization: `Bearer ${localStorage.getItem("token")}` }
        })
    }
    const handlePurchaseCard = async () => {

        if (setAlert) setAlert({
            showAlert: true,
            alertContent: <ConfirmTransaction callback={buyCard} price={cards[currentIndex].amount * parseFloat(quantityRef.current?.value ?? "1")} header={cards[currentIndex].displayName} details={<p>
                Are you sure you want to buy Exam card for <span className='font-bold'>{value?.toString().trim() ?? inputValue.trim()}</span>
            </p>} />,
            alertDismissible: false
        })
    }
    return (
        <ThemeProvider theme={theme}>
            <div className='pt-[20px] pb-[50px] px-3 md:px-0 w-full   lg:max-w-5xl  md:max-w-[540px] sm:max-w-[540px] max-w-[340px] mx-auto my-2'>
                <div className='flex md:flex-row flex-col justify-start  md:justify-between  w-full md:items-end items-start'>
                    <p className='font-Bestigia md:text-4xl text-2xl font-bold text-connect-red'>Exam Pins</p>
                    <p>Available Balance: <span className='text-connect-red'># {parseFloat(authUser?.walletBalance?.toString() ?? "0").toLocaleString("en-Us")}</span></p>
                </div>
                {cards.length > 0 ? <div className='w-full flex flex-col items-center'>
                    <p className='text-black opacity-75 my-10'>Kindy Select One</p>
                    {cards.map((e, index) => (<p className={`px-10 py-5 font-bold cursor-pointer mx-5 ${currentIndex === index ? "bg-yellow" : "bg-ash"}`}>{e.displayName}</p>))}
                </div> : <div>Loading</div>
                }
                <div>
                    <p className='mt-5 font-bold'>Enter Reciepients Phone Number </p>

                    <Autocomplete
                        className='w-full my-3 border-none'
                        freeSolo
                        options={authUser?.recentCreditedNumber ?? []}
                        value={value}
                        inputValue={inputValue}
                        onInputChange={handleInputChange}
                        onChange={(event, newValue) => {

                            setValue(newValue);
                        }}
                        renderInput={(params) => (
                            <TextField {...params} placeholder='Insert Phone Number'
                                variant="outlined" />
                        )}
                    />


                </div>
                <div>
                    <p className='mt-5 font-bold'>Quantity </p>

                    <div className='outline outline-[1px] outline-ash focus-within:outline-grey p-3 rounded-md my-3'>
                        <input type='number' ref={quantityRef} min={1} className='border-none outline-none focus:outline-none w-full' />
                    </div>

                </div>
                <p className='mt-20 px-10 py-3 bg-yellow font-bold cursor-pointer max-w-[350px] mx-auto text-center rounded-md' onClick={(e) => {
                    handlePurchaseCard()
                }}>
                    Pay # {cards[currentIndex]?.amount ?? 0 * parseFloat(quantityRef.current?.value ?? "1")}
                </p>

            </div >
        </ThemeProvider>
    )
}

export default CardSub