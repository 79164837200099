import React from 'react'
import CompanyLogo from "../../assets/logo.png";
import { BsTelephoneFill } from "react-icons/bs"
import { AiTwotoneMail, AiOutlineInstagram, AiOutlineTwitter } from "react-icons/ai"
import { CiFacebook, CiLinkedin } from "react-icons/ci";

const Footer = () => {
    const currentYear = new Date().getFullYear();
    return (
        <div className='w-full  relative py-[50px] overflow-clip bg-gradient-to-tr from-connect-dark-red via-connect-red to-connect-dark-red px-3'>
            {/* This is the content container */}
            <div className='flex flex-col items-start  w-full  lg:max-w-5xl  md:max-w-[540px] md:px-0 px-[15px] mx-auto my-4  justify-evenly'>
                <div className='text-white text-sm font-thin flex md:flex-row flex-col md:justify-between w-full'>
                    <div className='flex flex-col'>
                        {/* Contains Company Details */}
                        <img src={CompanyLogo} alt='logo' width={200} />
                        <p className='py-5'>No 25, Oladipo Street, <br /> Gaa-Akanbi, Ilorin, Kwara State, Nigeria</p>
                        <p className='py-5'>Copyright &copy; {currentYear} connectsphere.com.ng <br />All rights reserved</p>
                    </div>
                    <div className='flex flex-row'>
                        {/* Company Info and Support */}
                        <div className='flex flex-col mx-5'>
                            {/* <p className='font-bold text-lg'> Company</p> */}
                            {/* <ul className='my-4'>
                                <li className='my-3'>About Us</li>
                                <li className='my-3'>Contact Us</li>
                                <li className='my-3'>Pricing</li>
                                <li className='my-3'>Testionials</li>
                            </ul> */}
                        </div>
                        <div className='flex flex-col mx-5'>
                            {/* <p className='font-bold text-lg'> Support</p> */}
                            {/* <ul className='my-4'>
                                <li className='my-3'>Help Center</li>
                                <li className='my-3'>Terms of service</li>
                                <li className='my-3'>Legal</li>
                                <li className='my-3'>Privacy policy</li>
                            </ul> */}
                        </div>
                    </div>
                    <div>
                        {/* Stay up to date */}
                        <p className='font-bold text-lg'>Stay up to date</p>

                        <p className='flex my-4'><BsTelephoneFill className='mx-2 ' /> 09035683177</p>
                        <p className='flex'><AiTwotoneMail className='mx-2' /> support@connectsphere.com.ng</p>
                    </div>
                </div>
                <div className='flex text-white my-2'>
                    {/* Contains Social Icons */}
                    {/* <div className='w-[40px] h-[40px] bg-connect-dark-red rounded-full flex justify-center items-center mx-2'>
                        <a href='https://www.instagram.com/connect_sphere_official/' target='_blank' rel='noreferrer' >
                            <AiOutlineInstagram size={20} />
                        </a>

                    </div>
                    <div className='w-[40px] h-[40px] bg-connect-dark-red rounded-full flex justify-center items-center mx-2'>
                        <a href='https://web.facebook.com/profile.php?id=61552540560030' target='_blank' rel='noreferrer' >
                            <CiFacebook size={20} />
                        </a>

                    </div>
                    <div className='w-[40px] h-[40px] bg-connect-dark-red rounded-full flex justify-center items-center mx-2'>
                        <a href='https://twitter.com/connect_sphere' target='_blank' rel='noreferrer'>
                            <AiOutlineTwitter size={20} />
                        </a>

                    </div>
                    <div className='w-[40px] h-[40px] bg-connect-dark-red rounded-full flex justify-center items-center mx-2'>
                        <a href='https://www.linkedin.com/company/95052325/admin/feed/posts/' target='_blank' rel='noreferrer'>
                            <CiLinkedin size={20} />
                        </a>

                    </div> */}
                </div>
            </div>

        </div>
    )
}

export default Footer