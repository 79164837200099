import { useContext, useEffect, useState } from "react";
import ConnectLogo from "../assets/logo.png";
import { NavPositionContext } from "../context/nav_position";
import { AnimatePresence, motion } from "framer-motion";
import Hamburger from 'hamburger-react'
import { AlertContext } from "../context/alert_state";
import Login from "../alerts/Login";
import SignIn from "../alerts/SignIn";
import { useNavigate, useLocation } from "react-router-dom";
import { AuthUserContext } from "../context/auth_user_state";
import { BiLogOut } from "react-icons/bi"


const NavBar = () => {
    const { currentNavPos, setCurrentPost } = useContext(NavPositionContext);
    const [isOpen, setOpen] = useState(false)
    const { alert, setAlert } = useContext(AlertContext);
    const navigate = useNavigate()
    const location = useLocation()
    const { authUser, setAuthUser } = useContext(AuthUserContext)


    return (
        <div className="bg-connect-red shadow-lg  z-30 w-full text-white text-base fixed top-0 left-0">
            <div className="relative">
                <div className=' flex flex-row justify-between items-center lg:max-w-5xl  px-[15px] lg:px-[0px] mx-auto py-3 '>
                    <div className="md:basis-3/12 flex  justify-start">
                        <div className="md:h-[60px] h-[50px]">
                            <img className="object-contain w-full h-full" src={ConnectLogo} alt="logo" />
                        </div>
                    </div>


                    <ul className="md:flex hidden basis-5/12 justify-evenly ">
                        <li className="cursor-pointer" onClick={() => {
                            if (setCurrentPost) {
                                setCurrentPost(0)
                            }
                            const home = document.getElementById("home")
                            home?.scrollIntoView({ behavior: "smooth" })
                            if (authUser) {
                                navigate("/dashboard")
                            }
                        }}>
                            {location.pathname === "/" ? "Home" : "Dashboard"}
                            {/* Selected Indicator */}
                            <AnimatePresence>
                                {currentNavPos === 0 && <motion.span
                                    initial={{
                                        opacity: 0
                                    }}
                                    animate={{
                                        opacity: 1
                                    }}
                                    className="w-full h-[0.5px] bg-yellow block"></motion.span>}
                            </AnimatePresence>

                        </li>
                        <li className="cursor-pointer" onClick={() => {
                            if (setCurrentPost) {
                                setCurrentPost(1)
                            }
                            const home = document.getElementById("services")
                            home?.scrollIntoView({ behavior: "smooth" })
                        }}>
                            Services
                            {/* Selected Indicator */}
                            <AnimatePresence>

                                {currentNavPos === 1 && <motion.span
                                    initial={{
                                        opacity: 0
                                    }}
                                    animate={{
                                        opacity: 1
                                    }}
                                    className="w-full h-[0.5px] bg-yellow block"></motion.span>}
                            </AnimatePresence>

                        </li>
                        <li className="cursor-pointer" onClick={() => {
                            if (setCurrentPost) {
                                setCurrentPost(2)
                            }
                            const home = document.getElementById("pricing")
                            home?.scrollIntoView({ behavior: "smooth" })
                        }}>
                            Pricing
                            {/* Selected Indicator */}
                            <AnimatePresence>

                                {currentNavPos === 2 && <motion.span
                                    initial={{
                                        opacity: 0
                                    }}
                                    animate={{
                                        opacity: 1
                                    }} className="w-full h-[0.5px] bg-yellow block"></motion.span>}
                            </AnimatePresence>

                        </li>
                        <li className="cursor-pointer" onClick={() => {
                            if (setCurrentPost) {
                                setCurrentPost(3)
                            }
                            const home = document.getElementById("testimonial")
                            home?.scrollIntoView({ behavior: "smooth" })
                        }}>
                            Testimonial
                            {/* Selected Indicator */}
                            <AnimatePresence>
                                {currentNavPos === 3 && <motion.span initial={{
                                    opacity: 0
                                }}
                                    animate={{
                                        opacity: 1
                                    }} className="w-full h-[0.5px] bg-yellow block"></motion.span>}
                            </AnimatePresence>

                        </li>
                        <li className="cursor-pointer" onClick={() => {
                            if (setCurrentPost) { setCurrentPost(4) }
                            const home = document.getElementById("faqs")
                            home?.scrollIntoView({ behavior: "smooth" })
                        }}>
                            FAQs
                            {/* Selected Indicator */}
                            <AnimatePresence>

                                {currentNavPos === 4 && <motion.span initial={{
                                    opacity: 0,

                                }}
                                    animate={{
                                        opacity: 1
                                    }} className="w-full h-[0.5px] bg-yellow block"></motion.span>}
                            </AnimatePresence>

                        </li>
                    </ul>

                    {!authUser && <ul className="flex md:basis-3/12 basis-8/12 md:justify-evenly justify-end text-black sm:text-base text-sm">
                        <li className="md:inline hidden md:px-5 md:py-2 px-2  py-2 bg-white rounded-md cursor-pointer" onClick={(e) => {

                            if (setAlert) { setAlert({ alertContent: <Login />, showAlert: true }) };
                        }}>
                            Login
                        </li>
                        <li className="md:inline hidden md:px-5 md:py-2 px-2  py-2 bg-yellow rounded-md cursor-pointer" onClick={(e) => {

                            if (setAlert) setAlert({ alertContent: <SignIn />, showAlert: true });
                        }}>
                            Sign up
                        </li>
                        <div className="md:hidden w-[35px] h-[35px]">
                            <Hamburger color="#ffffff" toggled={isOpen} toggle={setOpen} />
                        </div>


                    </ul>}
                    {authUser && <ul className="flex md:basis-3/12 basis-8/12 md:justify-evenly justify-end text-black sm:text-base text-sm">
                        <li className="md:inline hidden md:px-5 md:py-2 px-2  py-2 bg-white rounded-md cursor-pointer" onClick={(e) => {
                            if (setAlert) setAlert({ alertContent: <></>, showAlert: false });
                            if (location.pathname === "/") {
                                navigate("/dashboard")
                            } else {
                                navigate("/")
                            }

                        }}>
                            {location.pathname === "/" ? "To Dashboard" : "To Home Page"}
                        </li>
                        <div className="md:hidden w-[35px] h-[35px]">
                            <Hamburger color="#ffffff" toggled={isOpen} toggle={setOpen} />
                        </div>


                    </ul>}


                </div>
                <AnimatePresence>

                    {isOpen && <motion.div

                        initial={{
                            height: 0,
                            opacity: 0
                        }}
                        animate={{
                            height: window.innerHeight,
                            opacity: 1
                        }}
                        transition={{
                            duration: 0.3
                        }}
                        exit={{
                            height: 0,
                            opacity: 0
                        }}
                        className={"md:hidden absolute w-[100vw] h-[100vh] bg-service-gold "}>


                        <ul className="flex flex-col  basis-5/12 justify-evenly text-connect-red p-3 ">
                            <li className="cursor-pointer" onClick={() => {
                                if (setCurrentPost) {
                                    setCurrentPost(0)
                                }
                                if (location.pathname !== "/") {
                                    navigate("/dashboard")
                                }
                                const home = document.getElementById("home")
                                home?.scrollIntoView({ behavior: "smooth" })
                                setOpen(false)
                            }}>
                                {location.pathname === "/" ? "Home" : "Dashboard"}
                                {/* Selected Indicator */}
                                <AnimatePresence>
                                    {currentNavPos === 0 && <motion.span
                                        initial={{
                                            opacity: 0
                                        }}
                                        animate={{
                                            opacity: 1
                                        }}
                                        className="w-full h-[0.5px] bg-yellow block"></motion.span>}
                                </AnimatePresence>

                            </li>
                            <li className="cursor-pointer" onClick={() => {
                                if (setCurrentPost) {
                                    setCurrentPost(1)
                                }
                                const home = document.getElementById("services")
                                home?.scrollIntoView({ behavior: "smooth" })
                                setOpen(false)
                            }}>
                                Services
                                {/* Selected Indicator */}
                                <AnimatePresence>

                                    {currentNavPos === 1 && <motion.span
                                        initial={{
                                            opacity: 0
                                        }}
                                        animate={{
                                            opacity: 1
                                        }}
                                        className="w-full h-[0.5px] bg-yellow block"></motion.span>}
                                </AnimatePresence>

                            </li>
                            <li className="cursor-pointer" onClick={() => {
                                if (setCurrentPost) {
                                    setCurrentPost(2)
                                }
                                setOpen(false)
                                const home = document.getElementById("pricing")
                                home?.scrollIntoView({ behavior: "smooth" })
                            }}>
                                Pricing
                                {/* Selected Indicator */}
                                <AnimatePresence>

                                    {currentNavPos === 2 && <motion.span
                                        initial={{
                                            opacity: 0
                                        }}
                                        animate={{
                                            opacity: 1
                                        }} className="w-full h-[0.5px] bg-yellow block"></motion.span>}
                                </AnimatePresence>

                            </li>
                            <li className="cursor-pointer" onClick={() => {
                                if (setCurrentPost) {
                                    setCurrentPost(3)
                                }
                                setOpen(false)
                                const home = document.getElementById("testimonial")
                                home?.scrollIntoView({ behavior: "smooth" })
                            }}>
                                Testimonial
                                {/* Selected Indicator */}
                                <AnimatePresence>
                                    {currentNavPos === 3 && <motion.span initial={{
                                        opacity: 0
                                    }}
                                        animate={{
                                            opacity: 1
                                        }} className="w-full h-[0.5px] bg-yellow block"></motion.span>}
                                </AnimatePresence>

                            </li>
                            <li className="cursor-pointer" onClick={() => {
                                setOpen(false)
                                if (setCurrentPost) { setCurrentPost(4) }
                                const home = document.getElementById("faqs")
                                home?.scrollIntoView({ behavior: "smooth" })
                            }}>
                                FAQs
                                {/* Selected Indicator */}
                                <AnimatePresence>

                                    {currentNavPos === 4 && <motion.span initial={{
                                        opacity: 0,

                                    }}
                                        animate={{
                                            opacity: 1
                                        }} className="w-full h-[0.5px] bg-yellow block"></motion.span>}
                                </AnimatePresence>

                            </li>
                        </ul>

                        {!authUser && <ul className=" mx-2 md:mx-0 md:flex md:basis-3/12 basis-8/12 md:justify-evenly justify-end text-black sm:text-base text-sm">
                            <li className="block  md:px-5 md:py-2 px-2 max-w-[150px] md:my-0 my-2 py-2 text-center bg-white rounded-md cursor-pointer" onClick={(e) => {

                                if (setAlert) { setAlert({ alertContent: <Login />, showAlert: true }) };
                            }}>
                                Login
                            </li>
                            <li className="block  md:px-5 md:py-2 px-2  max-w-[150px] md:my-0 my-2 text-center py-2 bg-yellow rounded-md cursor-pointer" onClick={(e) => {

                                if (setAlert) setAlert({ alertContent: <SignIn />, showAlert: true });
                            }}>
                                Sign up
                            </li>
                            <div className=" w-[35px] h-[35px]">
                                <Hamburger color="#ffffff" toggled={isOpen} toggle={setOpen} />
                            </div>


                        </ul>}
                        {authUser && <div className="flex text-connect-red items-center cursor-pointer" onClick={(_) => {
                            navigate("/")
                            localStorage.clear()
                            if (setAuthUser) setAuthUser(undefined);
                            setOpen(false)
                        }}>
                            <p className="px-2">Logout</p>
                            <BiLogOut />
                        </div>

                        }


                    </motion.div>}
                </AnimatePresence>

            </div>

        </div>

    )
}

export default NavBar