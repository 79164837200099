import React, { ReactNode, useContext, useState } from 'react'
import { GiCancel } from "react-icons/gi"
import { AlertContext } from '../context/alert_state'

import { ImCancelCircle } from "react-icons/im";
export interface UserAlertInterface {
    title: string,
    content: string,
    ctaUrl: string,
    showAlert: boolean,
    showCta: boolean,
    ctaBtnLabel: string,
}


const UserAlert = (props: UserAlertInterface) => {
    const { alert, setAlert } = useContext(AlertContext)

    return (
        <div className='w-[250px] md:w-[350px]  bg-white px-5 md:px-10 py-3 rounded-xl text-center'>
            <div>
                <p className='text-base md:text-3xl text-connect-red font-bold my-3 md:my-5'>{props.title}</p>
                {props.content}
                {props.showCta && <a href={props.ctaUrl} target='_blank' rel='noreferrer' className='px-5 md:px-10 py-2 md:py-3 bg-yellow rounded-xl cursor-pointer my-3 block' >{props.ctaBtnLabel}</a>}
                <div className='flex justify-center items-center  my-3 cursor-pointer' onClick={(_) => {
                    if (setAlert) setAlert({
                        alertContent: <></>,
                        showAlert: false,
                        alertDismissible: true
                    })
                }}>
                    <p className='mx-2 text-connect-red'>Close </p><GiCancel className='text-connect-red' />
                </div>
            </div>


        </div>
    )
}

export default UserAlert