import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import HomePage from './pages/home_page/HomePage';
import GlobalPageProvider from './pages/globals/GlobalPageProvider';
import DashBoard from './pages/dashboard/DashBoard';
import DataSub from './pages/data_subscription/DataSub';
import AirtimeSub from './pages/airtime_subscription/AirtimeSub';
import CustomAlert from './pages/globals/CustomAlert';
import ExamCard from './pages/exam_card/ExamCard';
import ChangePassword from './pages/change_password/ChangePassword';
import TransactionSummary from './pages/transaction_summary/TransactionSummary';
import NotFound from './pages/auxilary/NotFound';
import ErrorScreen from './pages/auxilary/ErrorScreen';
import ForgotPassword from './pages/forgot_password/forgot_password';



export const baseUrl = "https://connect-sphere.onrender.com"
// export const baseUrl = "http://localhost:4500"


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);


const router = createBrowserRouter([
  { path: "/", element: <HomePage />, errorElement: <ErrorScreen /> },
  { path: "/dashboard", element: <DashBoard />, errorElement: <ErrorScreen /> },
  { path: "/data", element: <DataSub />, errorElement: <ErrorScreen /> },
  { path: "/airtime", element: <AirtimeSub />, errorElement: <ErrorScreen /> },
  { path: "/exams", element: <ExamCard />, errorElement: <ErrorScreen /> },
  { path: "/change-password", element: <ChangePassword />, errorElement: <ErrorScreen /> },
  { path: "/transactions", element: <TransactionSummary />, errorElement: <ErrorScreen /> },
  { path: "/forgot", element: <ForgotPassword />, errorElement: <ErrorScreen /> },
  { path: "*", element: <NotFound /> }

])

root.render(
  <React.StrictMode>
    <GlobalPageProvider>
      <RouterProvider router={router} />


    </GlobalPageProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
