import React, { ReactNode, FC, useState, Dispatch, SetStateAction } from 'react'
import { NavPositionContext } from '../../context/nav_position'
import { AlertContext, AlertStateType, AlertType } from '../../context/alert_state'
import { AuthUserContext } from '../../context/auth_user_state'
import { AuthUser } from '../../models/AuthUser'
import { NetworkContext } from '../../context/network_context'
import { NetworkModel } from '../../models/NetworkModel'

interface GlobalInterface {
    children: ReactNode
}

const defaultType: AlertType = {
    alertContent: <></>,
    showAlert: false
}




const GlobalPageProvider: FC<GlobalInterface> = ({ children }) => {

    const [currNavPostion, setCurrNavPosition] = useState(0);
    const [alert, setAlert] = useState(defaultType);
    const [authUser, setAuthUser] = useState<AuthUser>()
    const [networks, setNetworks] = useState<NetworkModel[]>();
    return (<div>
        <AuthUserContext.Provider value={{ authUser, setAuthUser }}>
            <NetworkContext.Provider value={{ networks, setNetworks }}>
                <AlertContext.Provider value={{ alert, setAlert }}>
                    <NavPositionContext.Provider value={{
                        currentNavPos: currNavPostion,
                        setCurrentPost: setCurrNavPosition
                    }}>
                        {children}
                    </NavPositionContext.Provider>
                </AlertContext.Provider>
            </NetworkContext.Provider>
        </AuthUserContext.Provider>
    </div>

    )
}

export default GlobalPageProvider