import React, { useContext, useRef, useState } from 'react'
import { ImCancelCircle } from "react-icons/im";
import { AlertContext } from '../context/alert_state';
import SignIn from './SignIn';
import LoadingIcon from "../components/LoadingIcon";
import axios, { AxiosError } from 'axios';
import { baseUrl } from '..';
import ServiceAlert from './ServiceAlert';
import { AlertType } from './ServiceAlert';
import { AuthUser } from '../models/AuthUser';
import { useNavigate } from 'react-router-dom';
import { AuthUserContext } from '../context/auth_user_state';
import { motion, AnimatePresence } from 'framer-motion';
import UserAlert, { UserAlertInterface } from './UserAlert';

export interface DisplayAlert {
    alert: AlertType,
    message: string
}
const Login = () => {
    const { setAlert } = useContext(AlertContext);
    const [isLoading, setisLoading] = useState(false)
    const emailRef = useRef<HTMLInputElement | null>(null);
    const [customAlert, setCustomAlert] = useState<DisplayAlert | undefined>(undefined);
    const passwordRef = useRef<HTMLInputElement | null>(null);
    const navigate = useNavigate()

    const { authUser, setAuthUser } = useContext(AuthUserContext)

    async function handleSubmit() {
        setisLoading(true)
        try {
            setCustomAlert(undefined)
            const response = await axios.post(baseUrl + "/auth/login", {
                "email": emailRef.current?.value,
                "password": passwordRef.current?.value
            })
            const user = response.data["user"] as AuthUser
            localStorage.setItem("token", user.authToken)
            if (setAuthUser) setAuthUser(user);
            setCustomAlert({ alert: AlertType.sucess, message: "Sucessful Login" })
            setisLoading(false)
            setTimeout(() => {
                setCustomAlert(undefined)
                setisLoading(false)
                if (setAlert) setAlert({ alertContent: <></>, showAlert: false })
                navigate("/dashboard")

            }, 1000)
            fetchAlert()
        } catch (err) {
            const error = err as AxiosError;
            const errorResponse = error.response!.data as { error: string }
            setCustomAlert({ alert: AlertType.error, message: errorResponse.error })
            setisLoading(false)
        }

    }
    async function fetchAlert() {
        const data = (await axios.get(baseUrl + "/alert/all")).data as UserAlertInterface
        if (data.showAlert) {
            if (setAlert) setAlert({ alertContent: <UserAlert {...data} />, showAlert: true, alertDismissible: !data.showCta })
        }
    }
    return (
        <AnimatePresence>
            <motion.div initial={{
                opacity: 0,
                y: 10
            }} animate={{
                opacity: 1,
                y: 0
            }}
                transition={{
                    duration: 0.3
                }}
                exit={{
                    opacity: 0,
                    y: 10
                }}
                className='md:w-[500px] w-[290px] p-2 mx-auto relative flex flex-col'>
                <div className='bg-connect-red w-full rounded-lg pt-5 text-center'>

                    <p className='text-white font-bold text-lg'>Welcome Back! </p>
                    <div className='w-full bg-white rounded-lg p-5 mt-3 text-start'>
                        {customAlert && <ServiceAlert message={customAlert.message} alertType={customAlert.alert} />}
                        <div className='my-2'>
                            <p>Email</p>
                            <div className='focus-within:border-grey transition-all duration-200 w-full md:h-[40px] h-[30px] p-1 md:p-2 border border-black rounded-lg my-1'>
                                <input ref={emailRef} type='email' required className='w-full h-full outline-none border-none focus:border-none focus:outline-none' />
                            </div>
                        </div>

                        <div className='my-2'>
                            <p>Password</p>
                            <div className='focus-within:border-grey transition-all duration-200 w-full md:h-[40px] h-[30px] p-1 md:p-2 border border-black rounded-lg my-1'>
                                <input ref={passwordRef} type='password' required className='w-full h-full outline-none border-none focus:border-none focus:outline-none ' />
                            </div>
                        </div>
                        <p className='text-connect-red underline text-sm cursor-pointer' onClick={(_) => {
                            navigate("/forgot")
                        }}>Forgot password?</p>
                        {!isLoading ? <div className='my-4 w-full bg-connect-red text-white text-center font-semibold cursor-pointer p-3 mt-8 mb-4 rounded-lg' onClick={(e) => {
                            if (emailRef.current?.value == "" || passwordRef.current?.value == "") {
                                setCustomAlert({ alert: AlertType.error, message: "Email and Password cannot be empty" })
                            } else {
                                handleSubmit()
                            }
                        }}>
                            Sign In
                        </div> : <div className='my-4 w-full bg-connect-red text-white text-center font-semibold cursor-pointer p-3 mt-8 mb-4 rounded-lg h-[50px] flex items-center justify-center'>
                            <LoadingIcon className='animate-spin' />

                        </div>}
                        <div className='flex flex-row justify-center md:text-base text-xs'>
                            <p className='text-black opacity-60 '>Don't have an account?</p>
                            <p className='text-connect-red font-semibold mx-2 cursor-pointer' onClick={(e) => {
                                if (setAlert)
                                    setAlert({ alertContent: <SignIn />, showAlert: true, alertDismissible: false });
                            }}>Sign Up</p>
                        </div>

                    </div>

                </div>
                <div className='absolute top-0 right-0 text-white'>
                    <ImCancelCircle className='cursor-pointer' size={25} onClick={(e) => {
                        if (setAlert) {
                            setCustomAlert(undefined)
                            setisLoading(false)
                            setAlert({ alertContent: <></>, showAlert: false })
                        };
                    }} />
                </div>

            </motion.div>
        </AnimatePresence>
    )
}

export default Login