import { AiOutlineWarning } from "react-icons/ai"
export enum AlertType {
    error, sucess
}
interface ServiceAlertProp {
    message: string,
    alertType: AlertType
}

const ServiceAlert = (props: ServiceAlertProp) => {
    return <div className={props.alertType !== AlertType.sucess ? "w-full p-2 outline outline-[1px] text-yellow text-center outline-yellow rounded-md " : "w-full p-2 outline outline-[1px] text-center outline-green rounded-md text-green"}>
        {props.alertType !== AlertType.sucess && <AiOutlineWarning size={24} className="inline animate-pulse" />} {props.message}
    </div>;
}

export default ServiceAlert