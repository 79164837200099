import React from 'react'
import { FaLaptopCode } from "react-icons/fa"
import { FcCustomerSupport } from "react-icons/fc";
import { VscWorkspaceTrusted } from "react-icons/vsc"

const WhyChooseUs = () => {
    return (
        <div className='w-full  relative pt-[50px] overflow-clip'>
            {/* This is the content container */}
            <div className='flex flex-col items-center  w-full  lg:max-w-5xl  md:max-w-[540px] sm:max-w-[540px] max-w-[340px] mx-auto my-2'>
                <p className='font-Bestigia md:text-4xl text-2xl font-bold text-connect-red'>Why Choose Us?</p>
                <div className='flex md:flex-row flex-col  py-10 md:justify-evenly w-full justify-center items-center'>
                    <div className='bg-connect-red text-white flex flex-col items-center text-center w-[220px] h-[250px] justify-evenly p-4 shadow-lg my-3 md:my-0'>
                        <FaLaptopCode size={35} className='text-yellow' />
                        <p className='my-2 font-bold text-lg'>We are Automated</p>
                        <p className='text-sm'>Our delivery and wallet funding is automated. Any service purchased will get delivered to you instantly.</p>
                    </div>
                    <div className='bg-yellow text-white flex flex-col items-center text-center w-[220px] h-[250px] justify-evenly p-4 shadow-lg md:mt-20 my-3 md:my-0'>
                        <FcCustomerSupport size={35} className='text-connect-red' />
                        <p className='my-2 font-bold text-lg'>Customer Support</p>
                        <p className='text-sm'>Our customer service is just a click away. Don’t hesitate to consult us on anything. All transactions are attended to in 5-15mins</p>
                    </div>
                    <div className='bg-connect-red text-white flex flex-col items-center text-center w-[220px] h-[250px] justify-evenly p-4 shadow-lg my-3 md:my-0'>
                        <VscWorkspaceTrusted size={35} className='text-yellow' />
                        <p className='my-2 font-bold text-lg'>We are Reliable</p>
                        <p className='text-sm'>Our platform is a fully optimized platform for reliability and dependability. You get 100% value for any transaction you carry with us.</p>
                    </div>

                </div>


            </div>

            <div className='absolute top-[100px] left-0 bg-[url("/src/assets/connect-bg.png")]  bg-contain   md:w-[400px] md:h-[400px] w-[300px] h-[300px]'>

            </div>

        </div>
    )
}

export default WhyChooseUs