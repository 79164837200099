import { useState, useEffect } from "react";
import Par1 from "../../assets/par_mtn.png";
import Par2 from "../../assets/par_glo.png";
import Par3 from "../../assets/par_9mobile.png";
import Par4 from "../../assets/par_airtel.png";


import { } from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider, { Settings } from "react-slick";





interface TestimonialInterface {
    name: string,
    comment: string,
    imagePath: string
}
type TestimonialType = TestimonialInterface[]


const testimonials: TestimonialType = [
    { name: "Tony", comment: "ConnectSphere too fast abeg chai 100%", imagePath: Par1 },
    { name: "Lekan", comment: "It’s really fast and amazing, with cheap deals, thanks for this easy user interface.", imagePath: Par2 },
    { name: "Seyi", comment: "The Payment reflected fast! This is excellent", imagePath: Par3 },
    { name: "Favour", comment: "They have the best customer service that I have seen anywhere.", imagePath: Par3 },
    // { name: "Kenneth Maduike", comment: "I really love this site. So fast", imagePath: Par4 }
]

const Testimonial = () => {




    var settings: Settings = {
        dots: true,
        infinite: true,
        slidesToShow: 3,

        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 4000,
        centerMode: true,

        responsive: [{
            breakpoint: 600,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 2,
                initialSlide: 2
            }
        }]



    };

    return (


        <div id="testimonial" className='w-full  relative py-[50px] overflow-clip bg-yellow '>
            {/* This is the content container */}
            <div className='flex flex-col items-center  w-full  lg:max-w-5xl  md:max-w-[540px] sm:max-w-[540px] max-w-[340px] mx-auto my-4'>
                <p className='font-Bestigia md:text-4xl text-2xl font-bold text-black mb-4'>Testimonial</p>
            </div>
            <div className='absolute top-[400px] left-0 bg-[url("/src/assets/connect-bg.png")]  bg-contain   md:w-[400px] md:h-[400px] w-[300px] h-[300px]'>
            </div>
            <Slider {...settings} >
                {testimonials.map((e, index) => {

                    return <div className="px-5 " key={index}>
                        <section className='flex flex-col  items-center  text-center p-5 bg-service-gold h-[250px] w-[220px] rounded-lg shadow-md mx-4'>
                            <div className='h-[60px] w-[60px] rounded-full overflow-clip mb-[10px]'>
                                <img className="w-full h-full object-cover" src={e.imagePath} alt="testimonials" />
                            </div>

                            <div>
                                <p className='font-bold'>{e.name}</p>
                                <p className='text-sm'>{e.comment}</p>
                            </div>

                        </section>
                    </div>

                })}
            </Slider>
        </div>














    )
}

export default Testimonial