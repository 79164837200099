import React from 'react'
import { Link } from 'react-router-dom'
import NavBar from '../../components/NavBar'
const NotFound = () => {
    return (
        <div className="flex flex-col items-center justify-center h-screen">
            <NavBar />
            <h1 className="text-4xl font-bold mb-4">404 - Not Found</h1>
            <p className="text-lg mb-4">The page you're looking for does not exist.</p>
            <Link to="/" className="text-connect-red hover:underline">Go to Home</Link>
        </div>

    )
}

export default NotFound