import React, { useContext } from 'react'
import SmilingGuy from "../../assets/pix.png";
import { AlertContext } from '../../context/alert_state';
import SignIn from '../../alerts/SignIn';
import Login from '../../alerts/Login';
import { AuthUserContext } from '../../context/auth_user_state';
import { useNavigate } from 'react-router-dom';
import UserAlert, { UserAlertInterface } from '../../alerts/UserAlert';
import axios from 'axios';
import { baseUrl } from '../..';
const Onboarding = () => {
    const { alert, setAlert } = useContext(AlertContext);
    const { authUser, setAuthUser } = useContext(AuthUserContext)
    const navigate = useNavigate()
    async function fetchAlert() {
        const data = (await axios.get(baseUrl + "/alert/all")).data as UserAlertInterface
        if (data.showAlert) {
            if (setAlert) setAlert({ alertContent: <UserAlert {...data} />, showAlert: true, alertDismissible: !data.showCta })
        }
    }
    return (
        <div id='home' className='w-full h-[96vh] bg-gradient-to-r from-connect-red to-connect-dark-red pt-[100px] overflow-clip md:px-0 px-2'>
            <div className='flex flex-col md:flex-row h-[83vh] lg:max-w-5xl  md:max-w-[540px] px-[15px] md:px-0 mx-auto'>
                {/* Section 1 */}
                <div className='md:basis-1/2 md:h-[83vh] h-[42vh] text-white flex flex-col justify-evenly md:py-10 '>
                    <p className='md:text-5xl text-lg'>The Ultimate</p>
                    <p className='md:text-7xl font-bold text-3xl'>Bills Payment</p>
                    <p className='md:text-7xl font-bold text-3xl'>Experience</p>
                    <p className='font-thin md:text-base text-sm'>Enjoy seamless bill payments, with endless convenience <br /> while using our platform</p>
                    <p className=' py-4 bg-yellow md:w-52 w-32 mt-2 md:text-base text-sm text-center text-black rounded-md font-semibold cursor-pointer' onClick={(e) => {
                        if (authUser) {
                            navigate("/dashboard")
                            fetchAlert()
                            return
                        }
                        if (setAlert)
                            setAlert({ alertContent: <Login />, showAlert: true, alertDismissible: false });
                    }} >Get Started</p>
                </div>
                {/* Section 2 */}
                <div className='md:basis-1/2 md:h-[100vh] h-[51vh] w-full'>
                    <img className='w-full h-full object-contain' src={SmilingGuy} alt='smiling guy' />
                </div>

            </div>
        </div>
    )
}

export default Onboarding