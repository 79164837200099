import React, { ReactNode, useContext } from 'react'
import { createPortal } from 'react-dom'
import { AnimatePresence, motion } from 'framer-motion'
import { AlertContext } from '../../context/alert_state'



const CustomAlert = () => {
    const { alert, setAlert } = useContext(AlertContext);
    return createPortal(
        <div>
            <div className='relative'>
                <AnimatePresence>
                    {alert?.showAlert && <motion.div
                        onClick={(e) => {

                            if (setAlert && (alert.alertDismissible ?? true))
                                setAlert({ alertContent: <></>, showAlert: false })
                        }}

                        initial={{
                            opacity: 0
                        }}
                        animate={{
                            opacity: 0.5
                        }}
                        exit={{
                            opacity: 0
                        }}

                        className='w-[100vw]  h-[100vh] bg-black fixed  top-0 z-40'>

                    </motion.div>}
                </AnimatePresence>
            </div>

            {alert?.showAlert && <div id='invisible' className='fixed top-0 z-50 w-[100vw] h-[100vh] flex justify-center items-center' onClick={(_) => {
                if (alert.alertDismissible) {
                    if (setAlert) setAlert({ alertContent: <></>, showAlert: false, alertDismissible: true })
                }
            }}>
                <AnimatePresence>
                    {alert.alertContent}
                </AnimatePresence>
            </div>}
        </div>
        , document.getElementById("alert")!)


}


export default CustomAlert