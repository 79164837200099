import React, { useRef, useEffect, useState } from 'react';
import { MdOutlineExpandMore } from "react-icons/md"
import { allNetworks } from '../pages/airtime_subscription/AirtimeClass';

interface CustomSelectInterface {
    onChange: (id: string, index: number) => void
}


const CustomSelect = (props: CustomSelectInterface) => {

    const [showDropdown, setShowDropdown] = useState(false);
    const [currentIndex, setCurrentIndex] = useState(0);
    const componentRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (componentRef.current && !componentRef.current.contains(event.target as Node)) {
                setShowDropdown(false);
            }
        };

        if (showDropdown) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [showDropdown]);

    return (
        <div className="relative h-[60px] w-[120px] md:w-[150px]" ref={componentRef}>
            {/* The One that is displayed */}
            <div key={allNetworks[currentIndex].id} className='h-[50px] md:h-[60px] w-[90px] md:w-[130px] transition-all duration-300 outline outline-[1px] outline-connect-red rounded-lg  p-3 flex flex-row justify-center items-center' onClick={(_) => {
                setShowDropdown(true)
            }}>
                <div className='w-[80px] h-[50px]'>
                    <img src={allNetworks[currentIndex].image} alt="Logo" className='w-full h-full object-contain' />
                </div>
                <MdOutlineExpandMore size={24} />
            </div>


            {/* The Select Options */}
            {
                showDropdown && (
                    <div className='absolute w-[120px]  overflow-y-scroll overflow-x-hidden top-[-10px] shadow-xl bg-white z-10 '>
                        {allNetworks.map((e, index) => (
                            <div key={e.id} className='w-[120px] h-[70px] hover:shadow-lg  transition-all duration-300 hover:bg-[#FFC0CB]  p-3 flex flex-col justify-center items-center' onClick={(_) => {
                                setCurrentIndex(index)
                                props.onChange(e.id, index)
                                setShowDropdown(false)
                            }}>
                                <div className='w-[80px] h-[60px]'>
                                    <img src={e.image} alt="Logo" className='w-full h-full object-contain' />
                                </div>

                                {/* <p className='font-bold'>{e.label}</p> */}
                            </div>
                        ))}
                    </div>
                )
            }
        </div >
    );
};

export default CustomSelect;
