import React, { useContext, useEffect } from 'react'
import NavBar from '../../components/NavBar'
import DataEnq from '../data_subscription/DataEnq'
import Footer from '../home_page/Footer'
import AirtimeClass from './AirtimeClass'
import CustomAlert from '../globals/CustomAlert'
import { AuthUserContext } from '../../context/auth_user_state'
import { useNavigate } from 'react-router-dom'
import { baseUrl } from '../..'
import axios from 'axios'
import { AuthUser } from '../../models/AuthUser'
import LoadingScreen from '../../alerts/LoadingScreen'
import BackButton from '../../components/BackButton'

const AirtimeSub = () => {

    const { authUser, setAuthUser } = useContext(AuthUserContext);
    const navigate = useNavigate()
    const getUser = async () => {
        try {
            const response = await axios.get(baseUrl + "/auth/user", {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`
                }
            })
            if (setAuthUser) setAuthUser(response.data.user as AuthUser);
        } catch (err) {
            navigate("/")
        }

    }

    useEffect(() => {
        if (authUser === undefined) {
            getUser()
        }
    }, [authUser])
    useEffect(() => {
        window.scrollTo({ top: 0, behavior: "smooth" })
    })

    return (
        <div>
            {authUser !== undefined ? <div>
                <NavBar/>
                <BackButton/>
                <AirtimeClass />
                <DataEnq />
                <Footer />
                <CustomAlert />
            </div> : <LoadingScreen />}

        </div>
    )
}

export default AirtimeSub