import React, { useState } from 'react';
import axios from 'axios'; // You may need to install axios: npm install axios
import NavBar from '../../components/NavBar';
import { useNavigate } from 'react-router-dom';
import { baseUrl } from '../..';

const ForgotPassword = () => {
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate()
    const handleForgotPassword = async () => {
        setLoading(true);

        try {
            // Call your backend API to send a new password to the user's email
            const response = await axios.post(baseUrl + "/auth/reset-password", { email });

            // Assuming your backend returns a success message
            setMessage("Sucessful, please check your mail");
        } catch (error) {
            // Handle errors, e.g., display an error message to the user
            setMessage('User does not exist');
        }

        setLoading(false);
    };

    return (
        <div>
            <NavBar />
            <div className="flex flex-col items-center justify-center h-screen bg-service-gold">
                <h2 className="text-2xl font-bold mb-4 text-connect-red">Forgot Password</h2>

                <div className="mb-4">
                    <label className="block text-sm font-medium text-gray-700">Email</label>
                    <input
                        type="email"
                        className="mt-1 p-2 w-64 border rounded-md"
                        placeholder="Enter your email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                </div>

                {message && <p className="text-red-500">{message}</p>}

                <button
                    className="mt-4 p-2 bg-connect-red text-white rounded-md"
                    onClick={handleForgotPassword}
                    disabled={loading}
                >
                    {loading ? 'Sending...' : 'Send New Password'}
                </button>

                <p className="mt-4">
                    Remember your password? <span onClick={(_) => {
                        navigate("/")
                    }} className="text-connect-red underline cursor-pointer">Go back to login</span>
                </p>
            </div>
        </div>

    );
};

export default ForgotPassword;
