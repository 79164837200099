import React, { ChangeEvent, useContext, useEffect, useState } from 'react';
import { AuthUserContext } from '../../context/auth_user_state';
import { useNavigate } from 'react-router-dom';
import { baseUrl } from '../..';
import axios from 'axios';
import { AuthUser } from '../../models/AuthUser';
import NavBar from '../../components/NavBar';
import Footer from '../home_page/Footer';
import LoadingScreen from '../../alerts/LoadingScreen';
import { TransactionHistoryModel } from '../../models/TransactionHistory';
import TablePagination from '@mui/material/TablePagination';
import BackButton from '../../components/BackButton';

enum SortBy {
    NewToOld = "0",
    OldToNew = "1",
}

enum FilterBy {
    All = 'all',
    Data = 'data',
    EPin = 'ePin',
    Airtime = 'airtime',
}

const TransactionSummary = () => {
    const { authUser, setAuthUser } = useContext(AuthUserContext);
    const navigate = useNavigate();
    const [transactions, setTransactions] = useState<TransactionHistoryModel[]>([]);
    const [sortBy, setSortBy] = useState<SortBy>(SortBy.NewToOld);
    const [filterBy, setFilterBy] = useState<FilterBy>(FilterBy.All);
    const [allTransactions, setAllTransactions] = useState<TransactionHistoryModel[]>([]);

    const getUser = async () => {
        try {
            const response = await axios.get(baseUrl + '/auth/user', {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                },
            });
            if (setAuthUser) setAuthUser(response.data.user as AuthUser);
        } catch (err) {
            navigate('/');
        }
    };

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        })
        fetchHistoryList();
        if (authUser === undefined) {
            getUser();
        }
    }, [authUser]);

    const fetchHistoryList = async () => {
        try {
            const response = await axios.get(baseUrl + '/auth/trnxs', {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                },
            });
            const sortedTransactions = sortByTransactionDateTime(response.data.trnxs, sortBy === SortBy.OldToNew);
            setTransactions(sortedTransactions);
            setAllTransactions(response.data.trnxs);
        } catch (error) {
            // console.error('Error fetching transaction history:', error);
        }
    };

    const handleChangeSortBy = (event: ChangeEvent<HTMLSelectElement>) => {
        if (event.target.value === "0") {
            setSortBy(SortBy.NewToOld);
            const sortedTransactions = sortByTransactionDateTime(transactions, sortBy === SortBy.NewToOld);
            setTransactions(sortedTransactions);
        } else {
            setSortBy(SortBy.OldToNew);
            const sortedTransactions = sortByTransactionDateTime(transactions, sortBy === SortBy.NewToOld);
            setTransactions(sortedTransactions);
        }
        // setSortBy(SortBy(event.target.value));
    };

    const handleChangeFilterBy = (event: ChangeEvent<HTMLSelectElement>) => {
        const selectedFilter = event.target.value as FilterBy;
        setFilterBy(selectedFilter);
        const filteredTransactions = filterByTransactionType(allTransactions, selectedFilter);
        const sortedTransactions = sortByTransactionDateTime(filteredTransactions, sortBy === SortBy.NewToOld);
        setTransactions(sortedTransactions);
        setPage(0); // Reset page to the first page when the filter changes
    };


    function filterByTransactionType(data: TransactionHistoryModel[], transactionType: FilterBy) {
        if (transactionType === FilterBy.All) {
            return data;
        }
        return data.filter(item => item.trnxType === transactionType.toString());
    }

    function sortByTransactionDateTime(data: TransactionHistoryModel[], ascending = true) {
        return data.slice().sort((a, b) => {
            const dateA = new Date(a.trnxIntiatedDateTime).getTime();
            const dateB = new Date(b.trnxIntiatedDateTime).getTime();
            return ascending ? dateA - dateB : dateB - dateA;
        });
    }

    function formatDate(inputDate: string) {
        const daysOfWeek = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
        const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

        const date = new Date(inputDate);

        const dayOfWeek = daysOfWeek[date.getDay()];
        const month = months[date.getMonth()];
        const day = date.getDate();
        const year = date.getFullYear();
        const hours = date.getHours();
        const minutes = date.getMinutes();
        const amOrPm = hours >= 12 ? "pm" : "am";

        const formattedDate = `${dayOfWeek}, ${month} ${day}${getDaySuffix(day)}, ${year}\n${formatTime(hours, minutes)}${amOrPm}`;

        return formattedDate;
    }

    function getDaySuffix(day: number) {
        if (day >= 11 && day <= 13) {
            return "th";
        }
        const lastDigit = day % 10;
        switch (lastDigit) {
            case 1:
                return "st";
            case 2:
                return "nd";
            case 3:
                return "rd";
            default:
                return "th";
        }
    }

    function formatTime(hours: number, minutes: number) {
        const formattedHours = hours % 12 || 12; // Convert to 12-hour format
        const formattedMinutes = String(minutes).padStart(2, '0');
        return `${formattedHours}:${formattedMinutes}`;
    }

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };


    const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    return (
        <div className="overflow-clip">
            {authUser !== undefined ? (
                <div>
                    <NavBar />
                    <BackButton />
                    <div className="pt-[20px] pb-[50px] w-full lg:max-w-5xl md:max-w-[540px] sm:max-w-[540px] max-w-[340px] mx-auto my-2">
                        <div className="flex md:flex-row flex-col justify-start md:justify-between w-full md:items-end items-start">
                            <p className="font-Bestigia md:text-4xl text-2xl font-bold text-connect-red">Transaction History</p>
                        </div>
                        <div className="flex w-full space-x-2 my-4">
                            <label className="font-bold">Sort By:</label>
                            <select value={sortBy.toString()} onChange={handleChangeSortBy} className="border p-2 rounded-md">
                                <option value={SortBy.NewToOld.toString()}>New to Old</option>
                                <option value={SortBy.OldToNew.toString()}>Old to New</option>
                            </select>
                            <label className="font-bold">Filter By:</label>
                            <select value={filterBy.toString()} onChange={handleChangeFilterBy} className="border p-2 rounded-md">
                                <option value={FilterBy.All.toString()}>All</option>
                                <option value={FilterBy.Data.toString()}>Data</option>
                                <option value={FilterBy.EPin.toString()}>ePin</option>
                                <option value={FilterBy.Airtime.toString()}>Airtime</option>
                            </select>
                        </div>
                        <div className="hidden md:flex w-full bg-yellow p-4 rounded-md mt-10 font-bold">
                            <div className="basis-3/12">Date</div>
                            <div className="basis-2/12">Transaction Type</div>
                            <div className="basis-3/12">Description</div>
                            <div className="basis-2/12">Status</div>
                        </div>
                        <div className="hidden md:block">
                            {transactions.slice(page * rowsPerPage, (page + 1) * rowsPerPage).map((e, index) => (
                                <div className={`flex w-full outline outline-[1px] ${e.status === 'fail' ? 'outline-connect-red' : 'outline-green'} p-4 rounded-md my-3 `} key={index}>
                                    <div className="basis-3/12">{formatDate(e.trnxIntiatedDateTime)}</div>
                                    <div className="basis-2/12">{e.trnxType}</div>
                                    <div className="basis-3/12">{e.trnxDetails}</div>
                                    <div className="basis-2/12">{e.status}</div>
                                </div>
                            ))}
                        </div>
                        <div className="md:hidden flex flex-col">
                            {transactions.slice(page * rowsPerPage, (page + 1) * rowsPerPage).map((e, index) => (
                                <div className={`flex flex-col w-full outline outline-[1px] ${e.status === 'fail' ? 'outline-connect-red' : 'outline-green'} p-4 rounded-md my-3 `} key={index}>
                                    <div className="flex my-1">
                                        <span className="font-bold pr-2 basis-1/2">Transaction Date: </span>
                                        <p className="basis-1/2">{formatDate(e.trnxIntiatedDateTime)}</p>
                                    </div>

                                    <div className="flex my-1">
                                        <span className="font-bold pr-2 basis-1/2">Transaction Type: </span>
                                        <p className="basis-1/2">{e.trnxType}</p>
                                    </div>

                                    <div className="flex my-1">
                                        <span className="font-bold pr-2 basis-1/2">Transaction Details: </span>
                                        <p className="basis-1/2"> {e.trnxDetails}</p>
                                    </div>

                                    <div className="flex my-1">
                                        <span className="font-bold pr-2 basis-1/2">Transaction Status: </span>
                                        <p className="basis-1/2">{e.status}</p>
                                    </div>
                                </div>
                            ))}
                        </div>
                        <TablePagination
                            component="div"
                            count={transactions.length}
                            page={page}
                            onPageChange={handleChangePage}
                            rowsPerPage={rowsPerPage}
                            rowsPerPageOptions={window.innerWidth < 500 ? [] : undefined}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </div>
                    <Footer />
                </div>
            ) : (
                <LoadingScreen />
            )}
        </div>
    );
};

export default TransactionSummary;
