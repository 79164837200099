
import { AiOutlineLoading3Quarters } from "react-icons/ai"
interface LoadingInterface {
    className?: string,
    size?: number,
    color?: string
}
const LoadingIcon = (props?: LoadingInterface) => {
    return (
        <AiOutlineLoading3Quarters className={props?.className} size={props?.size ?? 24} color={props?.color ?? "#ffffff"} />
    )
}

export default LoadingIcon