import React, { useContext, useEffect, useRef, useState } from 'react'
import { AuthUserContext } from '../../context/auth_user_state';
import { useNavigate } from 'react-router-dom';
import { baseUrl } from '../..';
import axios from 'axios';
import { AuthUser } from '../../models/AuthUser';
import NavBar from '../../components/NavBar';
import Footer from '../home_page/Footer';
import LoadingScreen from '../../alerts/LoadingScreen';
import { AlertContext } from '../../context/alert_state';
import ConfirmTransaction from '../../alerts/ConfirmTransaction';
import CustomAlert from '../globals/CustomAlert';
import BackButton from '../../components/BackButton';

const ChangePassword = () => {
    const { authUser, setAuthUser } = useContext(AuthUserContext);
    const navigate = useNavigate()
    const oldPassordRef = useRef<HTMLInputElement | null>(null)
    const newPassordRef = useRef<HTMLInputElement | null>(null)
    const confirmNewPassordRef = useRef<HTMLInputElement | null>(null)
    const { alert, setAlert } = useContext(AlertContext)
    const [error, setError] = useState<string | null>(null)
    useEffect(() => {
        window.scrollTo({ top: 0, behavior: "smooth" })
    })

    const getUser = async () => {
        try {
            const response = await axios.get(baseUrl + "/auth/user", {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`
                }
            })
            if (setAuthUser) setAuthUser(response.data.user as AuthUser);
        } catch (err) {
            navigate("/")
        }

    }

    useEffect(() => {
        if (authUser === undefined) {
            getUser()
        }
    }, [authUser])


    async function changePassword() {
        return await axios.post(baseUrl + "/auth/change-password", {
            "oldPassword": oldPassordRef.current?.value,
            "newPassword": newPassordRef.current?.value
        }, {
            headers: { Authorization: `Bearer ${localStorage.getItem("token")}` }
        })
    }
    const handChangePassword = async () => {
        if ((newPassordRef.current?.value.length ?? 0) >= 5 && newPassordRef.current?.value === confirmNewPassordRef.current?.value) {
            if (setAlert) setAlert({
                showAlert: true,
                alertContent: <ConfirmTransaction callback={changePassword} header='Change Password' details={<p>
                    Are you sure you want to change your password ?
                </p>} />,
                alertDismissible: false
            })
        } else {
            setError("Password must be greater than 5 characters and match Confirm Password")
        }

    }

    return (
        <div className='w-[98vw] overflow-clip'>
            {authUser !== undefined ? <div>
                <NavBar />
                <BackButton />
                <div>
                    <div className='pt-[20px] pb-[50px] w-full   lg:max-w-5xl  md:max-w-[540px] sm:max-w-[540px] max-w-[340px] mx-auto my-2'>
                        <div className='flex md:flex-row flex-col justify-start  md:justify-between  w-full md:items-end items-start mb-10'>
                            <p className='font-Bestigia md:text-4xl text-2xl font-bold text-connect-red'>Change Password</p>
                        </div>
                        <div className='my-5'>
                            <p>Old Passsword</p>

                            <div className='outline outline-[1px] outline-ash focus-within:outline-grey p-3 rounded-md my-3'>
                                <input ref={oldPassordRef} className='border-none outline-none focus:outline-none w-full' />
                            </div>

                        </div>
                        <div className='my-5'>
                            <p>New Passsword</p>

                            <div className='outline outline-[1px] outline-ash focus-within:outline-grey p-3 rounded-md my-3'>
                                <input ref={newPassordRef} className='border-none outline-none focus:outline-none w-full' />
                            </div>

                        </div>
                        <div className='my-5'>
                            <p>Confirm New Passsword</p>

                            <div className='outline outline-[1px] outline-ash focus-within:outline-grey p-3 rounded-md my-3'>
                                <input ref={confirmNewPassordRef} className='border-none outline-none focus:outline-none w-full' />
                            </div>

                        </div>

                        {error && <p className='text-connect-red text-xs'>{error}</p>}
                        <p className='mt-20 px-10 py-3 bg-yellow font-bold max-w-[350px] mx-auto text-center rounded-md cursor-pointer' onClick={(e) => {
                            handChangePassword()
                        }}>
                            Change Password
                        </p>

                    </div >

                </div>
                <CustomAlert />
                <Footer />
            </div> : <LoadingScreen />}

        </div>
    )

}

export default ChangePassword