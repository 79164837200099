import React, { useContext, useRef, useState } from 'react'
import { ImCancelCircle } from "react-icons/im";
import { AlertContext } from '../context/alert_state';
import Login, { DisplayAlert } from './Login';
import ServiceAlert from './ServiceAlert';
import axios, { AxiosError } from 'axios';
import { baseUrl } from '..';
import { AlertType } from './ServiceAlert';
import { AuthUser } from '../models/AuthUser';
import { useNavigate } from 'react-router-dom';
import LoadingIcon from '../components/LoadingIcon';
import { motion } from 'framer-motion';
import UserAlert, { UserAlertInterface } from './UserAlert';

const SignIn = () => {
    const { setAlert } = useContext(AlertContext);
    const emailRef = useRef<HTMLInputElement | null>(null);
    const [customAlert, setCustomAlert] = useState<DisplayAlert | undefined>(undefined);
    const passwordRef = useRef<HTMLInputElement | null>(null);
    const confirmPasswordRef = useRef<HTMLInputElement | null>(null);
    const phoneNoRef = useRef<HTMLInputElement | null>(null);
    const [isLoading, setisLoading] = useState(false)

    const navigate = useNavigate();

    async function handleSubmit() {
        setisLoading(true);

        try {
            setCustomAlert(undefined);

            // Basic form validation
            if (!emailRef.current?.value || !passwordRef.current?.value || !confirmPasswordRef.current?.value || !phoneNoRef.current?.value) {
                setCustomAlert({ alert: AlertType.error, message: "Please fill in all the fields." });
                setisLoading(false);
                return;
            }

            // Email validation using regex
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            if (!emailRegex.test(emailRef.current?.value.trim())) {
                setCustomAlert({ alert: AlertType.error, message: "Please enter a valid email address." });
                setisLoading(false);
                return;
            }

            // Password length validation
            if (passwordRef.current?.value.trim().length < 5) {
                setCustomAlert({ alert: AlertType.error, message: "Password must be at least 5 characters long." });
                setisLoading(false);
                return;
            }

            // Password match validation
            if (passwordRef.current?.value !== confirmPasswordRef.current?.value) {
                setCustomAlert({ alert: AlertType.error, message: "Passwords do not match." });
                setisLoading(false);
                return;
            }

            // Other validations can be added as needed...

            const response = await axios.post(baseUrl + "/auth/signup", {
                "email": emailRef.current?.value.trim(),
                "phoneNumber": phoneNoRef.current.value.trim(),
                "password": passwordRef.current?.value.trim()
            });
            setCustomAlert({ alert: AlertType.sucess, message: response.data.message });
            setisLoading(false);
            setTimeout(() => {
                setCustomAlert(undefined);


                if (setAlert) setAlert({ alertContent: <Login />, showAlert: true });
            }, 1000);
            fetchAlert()
        } catch (err) {
            const error = err as AxiosError;

            const errorResponse = error.response?.data as { error: string };
            setCustomAlert({ alert: AlertType.error, message: errorResponse?.error ?? error.message });
            setisLoading(false);
        }
    }


    async function fetchAlert() {
        const data = (await axios.get(baseUrl + "/alert/all")).data as UserAlertInterface
        if (data.showAlert) {
            if (setAlert) setAlert({ alertContent: <UserAlert {...data} />, showAlert: true, alertDismissible: !data.showCta })
        }
    }


    return (
        <motion.div
            initial={{
                opacity: 0,
                y: 10
            }} animate={{
                opacity: 1,
                y: 0
            }}
            transition={{
                duration: 0.3
            }}
            exit={{
                opacity: 0,
                y: 10
            }}


            className='md:w-[500px] w-[90%] p-2 mx-auto relative flex flex-col'>
            <div className='bg-connect-red w-full rounded-lg pt-5 text-center'>
                <p className='text-white font-bold text-lg'>Sign Up </p>

                <div className='w-full bg-white rounded-lg p-5 mt-3 text-start'>
                    {customAlert && <ServiceAlert message={customAlert.message} alertType={customAlert.alert} />}
                    <div className='my-2'>
                        <p>Email</p>
                        <div className='focus-within:border-grey transition-all duration-200 w-full md:h-[40px] h-[30px] p-[2px] md:p-2 px-2 border border-black rounded-lg my-1'>
                            <input ref={emailRef} className='w-full h-full outline-none border-none focus:border-none focus:outline-none' />
                        </div>
                    </div>
                    <div className='my-2'>
                        <p>Phone Number</p>
                        <div className='focus-within:border-grey transition-all duration-200 w-full md:h-[40px] h-[30px] p-[2px] md:p-2 px-2 border border-black rounded-lg my-1'>
                            <input ref={phoneNoRef} className='w-full h-full outline-none border-none focus:border-none focus:outline-none' />
                        </div>
                    </div>
                    <div className='my-2'>
                        <p>Password</p>
                        <div className='focus-within:border-grey transition-all duration-200 w-full md:h-[40px] h-[30px] p-[2px] md:p-2 px-2 border border-black rounded-lg my-1'>
                            <input ref={passwordRef} type='password' className='w-full h-full outline-none border-none focus:border-none focus:outline-none' />
                        </div>
                    </div>
                    <div className='my-2'>
                        <p>Confirm Password</p>
                        <div className='focus-within:border-grey transition-all duration-200 w-full md:h-[40px] h-[30px] p-[2px] md:p-2 px-2 border border-black rounded-lg my-1'>
                            <input ref={confirmPasswordRef} type='password' className='w-full h-full outline-none border-none focus:border-none focus:outline-none' />
                        </div>
                    </div>
                    {!isLoading ? <div className='my-4 w-full bg-connect-red text-white text-center font-semibold cursor-pointer p-3 mt-8 mb-4 rounded-lg' onClick={(_) => {
                        handleSubmit()
                    }}>
                        Sign Up
                    </div> : <div className='my-4 w-full bg-connect-red text-white text-center font-semibold cursor-pointer p-3 mt-8 mb-4 rounded-lg h-[50px] flex items-center justify-center'>
                        <LoadingIcon className='animate-spin' />

                    </div>}
                    <div className='flex flex-row justify-center md:text-base text-xs'>
                        <p className='text-black opacity-60 '>Already Have an account?</p>
                        <p className='text-connect-red font-semibold mx-2 cursor-pointer' onClick={(e) => {
                            if (setAlert)
                                setAlert({ alertContent: <Login />, showAlert: true, alertDismissible: true });
                        }} >Sign in</p>
                    </div>

                </div>

            </div>
            <div className='absolute top-0 right-0 text-white'>
                <ImCancelCircle className='cursor-pointer' size={25} onClick={(e) => {
                    if (setAlert) setAlert({ alertContent: <></>, showAlert: false });
                }} />
            </div>

        </motion.div>
    )
}

export default SignIn