import React from 'react'
import { MdKeyboardBackspace } from "react-icons/md";
import { Link } from 'react-router-dom';
const BackButton = () => {
    return (
        <div className='mt-[100px] md:mt-[120px] flex flex-col  w-full  lg:max-w-5xl  md:max-w-[540px]  mx-auto my-2 md:px-0 px-[15px]'>
            <Link to="/dashboard" className='text-black flex '>
                <MdKeyboardBackspace size={25} /> <p className='text-grey'> Back</p>
            </Link>

        </div>
    )
}

export default BackButton