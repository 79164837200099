import React, { useEffect } from 'react'
import NavBar from '../../components/NavBar'
import Onboarding from './Onboarding'
import { useContext } from 'react'
import OurServices from './OurServices'
import Pricing from './Pricing'
import WhyChooseUs from './WhyChooseUs'
import FAQs from './FAQs'
import Testimonial from './Testimonials'
import ContactUs from './ContactUs'
import Footer from './Footer'
import CustomAlert from '../globals/CustomAlert'
import { AuthUserContext } from '../../context/auth_user_state'
import axios from 'axios'
import { baseUrl } from '../..'
import { AuthUser } from '../../models/AuthUser'
import { useNavigate } from "react-router-dom";

const HomePage = () => {

    const { authUser, setAuthUser } = useContext(AuthUserContext)
    const getUser = async () => {
        try {
            const response = await axios.get(baseUrl + "/auth/user", {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`
                }
            })
            if (setAuthUser) setAuthUser(response.data.user as AuthUser);
        } catch (err) {

        }
    }
    useEffect(() => {
        getUser()
    }, [authUser])
    return (
        <div >

            <NavBar />
            <Onboarding />
            <OurServices showAds={true} />
            <Pricing />
            <WhyChooseUs />
            <FAQs />
            <Testimonial />
            <ContactUs />
            <Footer />
            <CustomAlert />
        </div>
    )
}

export default HomePage