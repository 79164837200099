import CustomAlert from "../globals/CustomAlert";









const ContactUs = () => {

    return (


        <div className='w-full  relative py-[50px] overflow-clip '>

            {/* This is the content container */}
            <div className='flex flex-col items-center  w-full  lg:max-w-5xl  md:max-w-[540px] sm:max-w-[540px] max-w-[340px] mx-auto my-4 h-[250px] justify-evenly'>
                <p className='font-Bestigia md:text-4xl text-2xl font-bold text-connect-red mb-4'>Contact Us</p>
                <div className="px-5 text-center">
                    <p>Reach out to us with any questions, inquiries, or collaborations. We are here to listen, assist, and create incredible experiences together</p>
                    <a href="https://api.whatsapp.com/send?phone=2349035683177&text=" target="_self">
                        <p className="w-[150px] mx-auto py-2 bg-yellow my-10 cursor-pointer" >

                            Contact Support
                        </p>
                    </a>

                </div>
            </div>
            <div className='absolute top-[400px] left-0 bg-[url("/src/assets/connect-bg.png")]  bg-contain   md:w-[400px] md:h-[400px] w-[300px] h-[300px]'>
            </div>

        </div>
    )
}

export default ContactUs;