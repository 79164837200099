import { Dispatch, ReactNode, SetStateAction, createContext } from "react";

export interface AlertType {
    showAlert: boolean,
    alertContent: ReactNode,
    alertDismissible?: boolean
}



export interface AlertStateType {
    alert: AlertType | undefined,
    setAlert: Dispatch<SetStateAction<AlertType>> | undefined
}


export const AlertContext = createContext<AlertStateType>({ alert: undefined, setAlert: undefined })