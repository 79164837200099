import React, { useContext } from 'react'
import { AiOutlineWifi } from "react-icons/ai";
import { BsCashStack } from "react-icons/bs";
import { GiElectric } from "react-icons/gi"
import ImageCarousel from './ImageCarousel';
import { HiMiniAcademicCap } from "react-icons/hi2";
import { useNavigate } from 'react-router-dom';
import { AuthUserContext } from '../../context/auth_user_state';
import { MdOutlinePassword } from "react-icons/md"


interface OurServiceProps {
    showAds?: boolean
}

const OurServices = (props: OurServiceProps) => {
    const { authUser, setAuthUser } = useContext(AuthUserContext)
    const navigate = useNavigate()
    return (
        <div id='services' className='w-full  relative  md:pt-[50px] overflow-clip'>
            {/* This is the content container */}
            <div className='flex flex-col items-center  w-full  lg:max-w-5xl  md:max-w-[540px] sm:max-w-[540px] max-w-[340px] mx-auto my-2'>
                <p className='font-Bestigia md:text-4xl text-2xl font-bold text-connect-red'>Our Services</p>
                {/* Service Containers */}
                <div className='py-5 md:py-10 grid md:grid-cols-3 grid-cols-1'>
                    <section className='flex md:flex-col flex-row items-center m-5 text-start md:text-center p-5 bg-service-gold rounded-lg shadow-md cursor-pointer' onClick={
                        () => {
                            if (authUser) navigate("/data");
                        }
                    }>
                        <div className='bg-brown p-2 rounded-full m-4'>
                            <AiOutlineWifi size={35} />
                        </div>

                        <div>
                            <p className='font-bold'>Data Subscription</p>
                            <p className='text-sm'>Stay connected to your loved ones, by taking advantage of our cheap data rate</p>
                        </div>

                    </section>
                    <section className='flex md:flex-col flex-row items-center m-5 text-start md:text-center p-5 bg-service-gold rounded-lg shadow-md cursor-pointer' onClick={() => {
                        if (authUser) navigate("/airtime");
                    }}>
                        <div className='bg-brown p-2 rounded-full m-4'>
                            <BsCashStack size={35} />
                        </div>

                        <div>
                            <p className='font-bold'>Airtime Topup</p>
                            <p className='text-sm'>Stay connected to your loved ones, by taking advantage of our cheap airtime prices</p>
                        </div>

                    </section>
                    <section className='flex md:flex-col flex-row items-center m-5 text-start md:text-center p-5 bg-service-gold rounded-lg shadow-md cursor-pointer' onClick={(_) => {
                        if (authUser) navigate("/exams");
                    }
                    }>
                        <div className='bg-brown p-2 rounded-full m-4'>
                            <HiMiniAcademicCap size={35} />
                        </div>

                        <div>
                            <p className='font-bold'>Exam Pins</p>
                            <p className='text-sm'>Payment of scratch card for both WAEC and NECO</p>
                        </div>

                    </section>
                    {authUser && <section className='flex md:flex-col flex-row items-center m-5 text-start md:text-center p-5 bg-service-gold rounded-lg shadow-md cursor-pointer' onClick={(_) => {
                        if (authUser) navigate("/change-password");
                    }
                    }>
                        <div className='bg-brown p-2 rounded-full m-4'>
                            <MdOutlinePassword size={35} />
                        </div>

                        <div>
                            <p className='font-bold'>Change Password</p>
                            <p className='text-sm'>Change your password to enhance its security.</p>
                        </div>

                    </section>}




                </div>

            </div>

            <div className='absolute top-[400px] left-0 bg-[url("/src/assets/connect-bg.png")]  bg-contain   md:w-[400px] md:h-[400px] w-[300px] h-[300px] -z-10'>

            </div>
            {props.showAds && <ImageCarousel />}
        </div>
    )
}

export default OurServices