import React from "react";
import Partner1 from "../../assets/par_mtn.png";
import Partner2 from "../../assets/par_glo.png";
import Partner3 from "../../assets/par_airtel.png";
import Partner4 from "../../assets/par_9mobile.png";


export function PartnerList() {

    const partnersList = [Partner1, Partner2, Partner3, Partner4];
    return <div className='flex mx-auto  w-[320px] my-2 overflow-clip'>
        {partnersList.map((e, index) => <div className='h-[60px] mx-5 max-w-[50px]' ><img className='object-contain h-full w-full' alt='partners' src={e} /></div>)}
    </div>;
}
