import { Dispatch, SetStateAction, createContext } from "react";

interface NavPostionInterface {
    currentNavPos: number | undefined;
    setCurrentPost: Dispatch<SetStateAction<number>> | undefined
}



export const NavPositionContext = createContext<NavPostionInterface>({ currentNavPos: undefined, setCurrentPost: undefined });
