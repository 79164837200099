import React, { useState } from "react";
import { MdOutlineExpandMore } from 'react-icons/md'
import { motion, AnimatePresence } from "framer-motion";
import { Accordion, AccordionSummary, AccordionDetails, Typography } from '@mui/material'
import { SubSectionInterface } from "./FAQs";


interface AccordionProp {
    index: number, currSection: number,
    subSection: SubSectionInterface
}


export const CustomAccordion = (props: AccordionProp) => {
    const { currSection, index, subSection } = props;
    const [isExpanded, setIsExpanded] = useState(false);
    return <Accordion expanded={isExpanded} key={index} style={{
        backgroundColor: isExpanded ? "#cc300d" : "#ffffff"
    }} >
        <AccordionSummary onClick={(e) => {
            setIsExpanded(curr => !curr);
        }} expandIcon={<MdOutlineExpandMore />} aria-controls="panel1a-content" className='md:text-2xl' id="panel1a-header">
            <p className={`font-semibold ${!isExpanded ? "text-black" : "text-white"}`}>{subSection.subSectionQuestion}</p>
        </AccordionSummary>
        <AccordionDetails onClick={(e) => {
            setIsExpanded(curr => !curr);
        }}>
            <Typography className={`md:text-lg text-sm text-justify ${!isExpanded ? "text-black" : "text-white"}`} variant='body2'>
                {subSection.subSectionAnswer}

            </Typography>
        </AccordionDetails>
    </Accordion>;
}
